import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationState } from '../../redux/ConfigurationState';
import { useIsInRole } from '../../helpers/UseIsInRole';
import ConfigurationCategory from '../ConfigurationCategory';
import AmperageData from './AmperageData';
import { Roles } from '../../../../shared/enums/Roles';

interface AmperageCategoryProps {
  configurationState: ConfigurationState;
}

export const AmperageCategory = (props: AmperageCategoryProps): ReactElement => {
  const { t } = useTranslation();

  const isEditAllowed = useIsInRole([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.OPERATOR, Roles.OWNER_OPERATOR]);

  if (props.configurationState.amperageConfiguration != null) {
    return (
      <ConfigurationCategory
        id="amperageConfiguration"
        categoryTitle={t('amperageConfiguration')}
        CategoryData={
          <AmperageData
            amperageConfiguration={props.configurationState.amperageConfiguration}
            isEditAllowed={isEditAllowed}
          />
        }
      />
    );
  }
  return <div />;
};
