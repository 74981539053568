import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import { MultiValueStringAdvancedProperty } from './MultiValueStringAdvancedProperty';
import { BooleanAdvancedProperty } from './BooleanAdvancedProperty';
import { StringAdvancedProperty } from './StringAdvancedProperty';
import { NumberAdvancedProperty } from './AdvancedPropertyNumber';
import { StationValueSection } from './StationValueSection';

interface AdvancedCategoryProps {
  properties: AdvancedProperty[];
  compareModeEnabled: boolean;
}

export const AdvancedCategory: FC<AdvancedCategoryProps> = (props) => {
  const { t } = useTranslation();

  const PropertyComponent = (advancedProperty: AdvancedProperty): ReactElement => {
    switch (advancedProperty.JavascriptType) {
      case 'Boolean':
        return BooleanAdvancedProperty(advancedProperty);
      case 'String':
        if (advancedProperty.IsMultiValue) {
          return MultiValueStringAdvancedProperty(advancedProperty);
        }
        return StringAdvancedProperty(advancedProperty);
      case 'Number':
        return <NumberAdvancedProperty advancedProperty={advancedProperty} />;
      default:
        return StringAdvancedProperty(advancedProperty);
    }
  };

  const isHighlighted = (property: AdvancedProperty): boolean => {
    if (property.StationValue !== property.PropertyValue && props.compareModeEnabled) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div>
        {props.compareModeEnabled && (
          <div className="compare-value-titles">
            <div className="csnms-values-title">{t('csnmsValue')}</div>
            <div className="station-values-title">{t('stationValue')}</div>
          </div>
        )}

        {props.properties.map((p) => {
          return (
            <div key={p.Name}>
              <div className={`property-values ${isHighlighted(p) ? 'highlight' : ''}`}>
                {PropertyComponent(p)}
                <StationValueSection compareModeEnabled={props.compareModeEnabled} advancedProperty={p} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
