import React, { ChangeEvent, FocusEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance, Input, InputRef } from 'antd';
import { requiredWithMessage } from '../../helpers/formValidation';
import TooltipLabel from '../../../../shared/components/forms/ToolTipLabel';
import { useIsUserAdmin } from '../../helpers/UseIsAdmin';

interface MacAddressProps {
  form: FormInstance;
  macAddressInitialValue?: string;
}

const MAX_DIGITS = 16;
const MAX_TEXT_LEN = 23;
const REGEX_MATCH_GROUPS = /([a-f0-9]{2})([a-f0-9]{2})/i;
const REGEX_FILTER_KEYPRESS = /[a-f\d]/i;
const REGEX_FILTER_TEXT = /[^a-f0-9]/gi;

function TokenizeMacAddress(macAddress: string): string {
  let str = macAddress;
  while (REGEX_MATCH_GROUPS.test(str)) {
    str = str.replace(REGEX_MATCH_GROUPS, '$1:$2');
  }
  return str;
}

function PadMacAddress(macAddress: string): string {
  if (macAddress.length < MAX_DIGITS) {
    return macAddress.padStart(MAX_DIGITS, '0');
  }
  return macAddress;
}

// Expects a full Mac Address (16 chars or 23 with separators)
export function ConvertMacAddressToGuid(macAddress: string): string {
  if (!macAddress) return '';
  const cleanMac = CleanMacAddress(macAddress);
  const first = cleanMac.substr(0, 4);
  const second = cleanMac.substr(4, 12);
  return `00000000-0000-0000-${first}-${second}`;
}

function ConvertGuidToMacAddress(guid?: string): string {
  if (!guid) return '';
  let value = guid.replace(/-/g, '');
  value = value.substr(value.length - MAX_DIGITS, MAX_DIGITS).toUpperCase();
  return TokenizeMacAddress(value);
}

function CleanMacAddress(macAddress: string): string {
  return macAddress.replace(REGEX_FILTER_TEXT, '').toUpperCase();
}

export const MacAddress = (props: MacAddressProps): ReactElement => {
  const { t } = useTranslation();
  const isAdmin = useIsUserAdmin();
  const [previousCursorPosition, setPreviousCursorPosition] = useState(0);
  const [previousMacLength, setPreviousMacLength] = useState(0);
  const [currentMacLength, setCurrentMacLength] = useState(0);
  const inputElRef = React.createRef<InputRef>();

  useEffect(() => {
    props.form.setFieldsValue({
      MacAddress: ConvertGuidToMacAddress(props.macAddressInitialValue),
    });
  }, [props]);

  useEffect(() => {
    // Adjust cursor position. Increment by 1 on simple char insert. By 2 if a colon (:) was also added.
    let position = previousCursorPosition;
    if (currentMacLength > previousMacLength) position += currentMacLength - previousMacLength;
    if (inputElRef.current !== null) inputElRef.current.setSelectionRange(position, position);
  }, [previousCursorPosition, previousMacLength, currentMacLength, inputElRef]);

  const formatMAC = (oldValue: string, isFinal: boolean): void => {
    let newValue = CleanMacAddress(oldValue);
    if (isFinal) newValue = PadMacAddress(newValue);
    newValue = TokenizeMacAddress(newValue);

    if (newValue !== oldValue) {
      setCurrentMacLength(newValue.length);
      props.form.setFieldsValue({
        MacAddress: newValue,
      });
    }
  };

  const onKeyPress = (e: React.KeyboardEvent): void => {
    const pressedKey = String.fromCharCode(e.which);
    if (!REGEX_FILTER_KEYPRESS.test(pressedKey)) e.preventDefault();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.selectionStart !== null) setPreviousCursorPosition(e.target.selectionStart);
    const { value } = e.target;
    setPreviousMacLength(value.length);
    formatMAC(value, false);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    formatMAC(value, true);
  };

  return (
    <div className="field-group">
      <TooltipLabel label={t('macAddress')} name="MacAddress" />
      <Form.Item name="MacAddress" className="field-value" rules={[requiredWithMessage(t('requiredFieldMacAddress'))]}>
        <Input
          disabled={!isAdmin}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          maxLength={MAX_TEXT_LEN}
          ref={inputElRef}
        />
      </Form.Item>
    </div>
  );
};
