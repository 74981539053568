import produce from 'immer';
import { ConfigurationState } from './ConfigurationState';
import {
  ConfigurationActionTypes,
  GET_CONFIGURATION_FAIL,
  GET_CONFIGURATION_SUCCESS,
  SET_ADVANCED_CONFIGURATION,
  SET_ADVANCED_PROPERTY,
  SET_AMPERAGE,
  SET_CONFIGURATION,
  SET_DISPLAY_CONFIGURATION,
  SET_DISPLAYED_CATEGORIES,
  SET_PRICING,
} from './ConfigurationActionTypes';
import { AdvancedProperty } from '../models/AdvancedProperty';
import { AdvancedConfigurationComponent } from '../models/AdvancedConfigurationComponent';

const initialState: ConfigurationState = {
  chargingStationInfo: undefined,
  chargingStationPricing: undefined,
  displayConfiguration: undefined,
  advancedComponents: [],
  fetchingFailed: false,
  displayedCategories: undefined,
  amperageConfiguration: undefined,
};

const ConfigurationReducer = (state = initialState, action: ConfigurationActionTypes): ConfigurationState => {
  switch (action.type) {
    case GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        chargingStationInfo: action.chargingStationInfo,
        chargingStationPricing: action.chargingStationPricing,
        displayConfiguration: action.displayConfiguration,
        advancedComponents: action.advancedComponents,
        fetchingFailed: false,
        amperageConfiguration: action.amperageConfiguration,
      };
    case GET_CONFIGURATION_FAIL:
      return { ...state, fetchingFailed: true };
    case SET_CONFIGURATION:
      return { ...state, chargingStationInfo: action.chargingStationInfo };
    case SET_PRICING:
      return { ...state, chargingStationPricing: action.pricing };
    case SET_DISPLAYED_CATEGORIES:
      return { ...state, displayedCategories: action.displayedCategories };
    case SET_ADVANCED_CONFIGURATION:
      return { ...state, advancedComponents: action.advancedComponents };
    case SET_DISPLAY_CONFIGURATION:
      return { ...state, displayConfiguration: action.displayConfiguration };
    case SET_AMPERAGE:
      return { ...state, amperageConfiguration: action.amperageConfiguration };
    case SET_ADVANCED_PROPERTY:
      return setAdvancedProperty(state, action.advancedProperty);
    default:
      return state;
  }
};

const setAdvancedProperty = (state: ConfigurationState, property: AdvancedProperty): ConfigurationState => {
  const modifiedAdvancedComponents: AdvancedConfigurationComponent[] = produce(state.advancedComponents, (draft) => {
    const componentIndex = state.advancedComponents.findIndex((c) =>
      c.Properties.find((p) => p.Name === property.Name)
    );

    if (componentIndex !== -1) {
      const propertyIndex = draft[componentIndex].Properties.findIndex((x) => x.Name === property.Name);
      draft[componentIndex].Properties[propertyIndex].PropertyValue = String(property.PropertyValue);
    }
  });
  return { ...state, advancedComponents: modifiedAdvancedComponents };
};

export { ConfigurationReducer };
