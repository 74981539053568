import React, { ReactNode } from 'react';

interface ErrorBoundaryProps {
  children?: ReactNode;
  onError?: (error: Error) => void;
  renderError?: (error: Error) => ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
}

// Error Boundary is not yet supported with hooks
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  public componentDidCatch(error: Error): void {
    const { onError } = this.props;
    if (onError) onError(error);
  }

  public render(): ReactNode {
    const { error } = this.state;
    const { renderError } = this.props;
    if (error && renderError) {
      return renderError(error);
    }
    return this.props.children;
  }
}
