import { Park } from '../models/Park';

export const GET_PARKS_SUCCESS = 'GET_PARKS_SUCCESS';
export const GET_PARKS_FAIL = 'GET_PARKS_FAIL';

export interface GetParksSuccess {
  type: typeof GET_PARKS_SUCCESS;
  parks: Park[];
}

export interface GetParksFail {
  type: typeof GET_PARKS_FAIL;
}

export type ParkActionTypes = GetParksSuccess | GetParksFail;
