import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';
import React, { FC, useState } from 'react';
import { PricingMode } from '../../../../shared/enums/PricingMode';
import { PricingDrawer } from './PricingDrawer';
import { ChargingStationPricing } from '../../models/pricing/ChargingStationPricing';
import { LabeledField } from '../../../../shared/components/forms/LabeledField';

interface PricingProps {
  stationId: string;
  stationPricing: ChargingStationPricing;
  isEditAllowed: boolean;
}

export const PricingData: FC<PricingProps> = (props) => {
  const { t } = useTranslation();
  const HourInSeconds = 3600;
  const MinuteInSeconds = 3600;
  const [visible, setVisible] = useState(false);

  const showDrawer = (): void => {
    setVisible(true);
  };
  const onClose = (): void => {
    setVisible(false);
  };

  const getPricingMode = (): PricingMode => {
    if (stationPricing.IsTwoPeriodsTariff) {
      return PricingMode.TwoPeriod;
    }
    return stationPricing.PricingMode;
  };

  const getPricingModeText = (): string => {
    switch (getPricingMode()) {
      case PricingMode.EnergyPerKwh:
        return t('energyPricing');
      case PricingMode.Hourly:
        return t('hourlyPricing');
      case PricingMode.PerChargingHour:
        return t('perChargingHourPricing');
      case PricingMode.Session:
        return t('sessionPricing');
      case PricingMode.TwoPeriod:
        return t('twoPeriodPricing');
      case PricingMode.Complex:
        return t('complexPricing');
      default:
        return '';
    }
  };

  const truncateValue = (value: number, pricingMode: PricingMode): string => {
    if (pricingMode === PricingMode.Session) return value.toFixed(2);

    const indexOfDot = value.toString().indexOf('.');
    const valueStrLength = value.toString().length;
    if (indexOfDot !== -1) {
      const precision = value.toString().substring(indexOfDot, valueStrLength - 1).length;
      if (precision === 1) {
        return value.toFixed(2);
      }
      if (precision > 5) {
        return value.toFixed(5);
      }
      return value.toFixed(precision);
    }

    return value.toFixed(2);
  };

  const { stationPricing } = props;

  const getSimpleTariffCostText = (): string =>
    `${truncateValue(
      Number(stationPricing.SimpleTariff?.Cost),
      getPricingMode()
    )}$ ${getPricingModeText().toLowerCase()}`;

  const getTwoPeriodTariffText = (): string => {
    if (stationPricing.TwoPeriodTariff !== undefined) {
      const twoPeriodTariff = stationPricing.TwoPeriodTariff;
      const firstPeriodDurationInHours = twoPeriodTariff.FirstPeriodDuration / HourInSeconds;
      const timeString = firstPeriodDurationInHours < 1 ? t('twoPeriodMinutes') : t('twoPeriodHours');
      const durationConversionFactor = firstPeriodDurationInHours < 1 ? MinuteInSeconds : HourInSeconds;

      return `${Number(twoPeriodTariff.FirstPeriodPrice).toFixed(2)}$ 
      ${t('twoPeriodPart1')} ${twoPeriodTariff.FirstPeriodDuration / durationConversionFactor} ${timeString}
      ${Number(twoPeriodTariff.SecondPeriodPrice).toFixed(2)}$ ${t('twoPeriodPart3')}`;
    }
    return '';
  };

  return (
    <div>
      <Drawer width="40rem" visible={visible} onClose={onClose} destroyOnClose>
        <PricingDrawer stationPricing={stationPricing} onClose={onClose} stationId={props.stationId} />
      </Drawer>

      <div>
        <LabeledField label={t('pricingMode')} value={getPricingModeText()} />

        {stationPricing.PricingMode !== PricingMode.Complex && (
          <LabeledField label={t('cost')} value={getSimpleTariffCostText()} />
        )}

        {stationPricing.IsTwoPeriodsTariff && <LabeledField label={t('cost')} value={getTwoPeriodTariffText()} />}

        {stationPricing.PricingMode === PricingMode.Complex && !stationPricing.IsTwoPeriodsTariff && (
          <LabeledField label={t('cost')} value={t('complexPricingDescription')} />
        )}
      </div>

      {props.isEditAllowed && (
        <div className="edit-configuration-button">
          <Button className="secondary-button" size="large" type="primary" onClick={showDrawer}>
            {t('edit')}
          </Button>
        </div>
      )}
    </div>
  );
};
