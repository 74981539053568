import React, { FC, useEffect, useState } from 'react';
import { Card, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons/lib';
import { AppState } from '../../../global/redux/store';
import { GetOwners } from '../../../owners/redux/OwnersActions';
import { Owner } from '../../../owners/models/Owner';

interface LargeOwnerSelectorComponentProps {
  onChange?: (owners: Owner[]) => void;
  initialOwners?: Owner[];
}

const LargeOwnerSelector: FC<LargeOwnerSelectorComponentProps> = (props) => {
  const ownerState = useSelector((state: AppState) => state.owners);
  const { Option } = Select;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedOwners, setSelectedOwners] = useState(props.initialOwners || []);
  const [selected, setSelected] = useState(undefined);

  useEffect(() => {
    dispatch(GetOwners());
  }, [dispatch]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onOwnersChange = (ownerName: string, OwnerInfo: any): void => {
    if (OwnerInfo.key != null && selectedOwners.find((s) => s.OwnerId === OwnerInfo.key) === undefined) {
      const newSelectedOwners = selectedOwners.concat({ OwnerId: OwnerInfo.key, Description: ownerName });
      setSelectedOwners(newSelectedOwners);

      if (props.onChange !== undefined) {
        props.onChange(newSelectedOwners);
      }
    }
    setSelected(undefined);
  };

  const removeOwner = (owner: Owner): void => {
    const newSelectedOwners = selectedOwners.filter((o) => o !== owner);
    setSelectedOwners(newSelectedOwners);

    if (props.onChange !== undefined) {
      props.onChange(newSelectedOwners);
    }
  };

  return (
    <Card
      title={t('associatedOwners')}
      type="inner"
      className="large-selector"
      extra={
        <Select
          showSearch
          className="medium-input"
          value={selected}
          onChange={onOwnersChange}
          placeholder={t('AddOwner')}
        >
          {ownerState.owners?.map((owner) => (
            <Option key={owner.OwnerId} value={owner.Description} label={owner.Description}>
              <div>{owner.Description}</div>
            </Option>
          ))}
        </Select>
      }
    >
      {selectedOwners.length === 0 && <div className="placeholder">{t('NoOwnerPlaceholder')}</div>}
      {selectedOwners.map((owner) => (
        <div key={owner.OwnerId} className="large-selector-element">
          <div>{owner.Description}</div> <CloseOutlined onClick={() => removeOwner(owner)} />
        </div>
      ))}
    </Card>
  );
};

export default LargeOwnerSelector;
