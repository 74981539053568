import { User } from './models/User';
import Configuration from '../../global/Configuration';
import HttpClient from '../../global/api/HttpClient';
import { UserActivity } from './models/UserActivity';
import { ApiErrors } from '../../shared/enums/ApiErrors';

export const GetUsersRequest = async (getInactiveUsers: boolean): Promise<User[]> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/users`, {
      params: { getInactiveUsers },
    });
    return response.data;
  } catch (ex) {
    return [];
  }
};

export const CreateUserRequest = async (user: User): Promise<User | undefined | ApiErrors> => {
  try {
    const response = await HttpClient.post(`${Configuration.CSNMS_BASE_URL}/api/security/users`, user);
    return response?.data;
  } catch (err: any) {
    if (err?.data?.Message === 'user already exists') {
      return ApiErrors.AlreadyExists;
    }
    return undefined;
  }
};

export const GetUserRequest = async (id: string): Promise<User | undefined> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/users/${id}`);
    return response.data;
  } catch (ex) {
    return undefined;
  }
};

export const GetUserActivityLogs = async (id: string): Promise<UserActivity[]> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/users/${id}/activitylogs`);
    return response.data;
  } catch (ex) {
    return [];
  }
};

export const GetCurrentUserRequest = async (): Promise<User | undefined> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/userinfo`);
    return response.data;
  } catch (ex) {
    return undefined;
  }
};

export const PatchUserRequest = async (id: string, user: User): Promise<User | undefined> => {
  try {
    const response = await HttpClient.patch(`${Configuration.CSNMS_BASE_URL}/api/security/users/${id}`, user);
    return response.data;
  } catch (ex) {
    return undefined;
  }
};

export const DeactivateUserRequest = async (id: string): Promise<number | undefined> => {
  try {
    const response = await HttpClient.patch(`${Configuration.CSNMS_BASE_URL}/api/security/users/${id}/deactivate`, {});
    return response.status;
  } catch (ex) {
    return undefined;
  }
};

export const ReactivateUserRequest = async (id: string): Promise<number | undefined> => {
  try {
    const response = await HttpClient.patch(`${Configuration.CSNMS_BASE_URL}/api/security/users/${id}/activate`, {});
    return response.status;
  } catch (ex) {
    return undefined;
  }
};
