import { Dispatch } from 'redux';
import {
  ConfigurationActionTypes,
  GET_CONFIGURATION_FAIL,
  GET_CONFIGURATION_SUCCESS,
  SET_ADVANCED_CONFIGURATION,
  SET_ADVANCED_PROPERTY,
  SET_AMPERAGE,
  SET_CONFIGURATION,
  SET_DISPLAY_CONFIGURATION,
  SET_DISPLAYED_CATEGORIES,
} from './ConfigurationActionTypes';
import { ChargingStationInfo } from '../models/ChargingStationInfo';
import { ConfigurationProfiles } from '../../../shared/enums/ConfigurationProfiles';
import { AdvancedConfigurationComponent } from '../models/AdvancedConfigurationComponent';
import { AdvancedProperty } from '../models/AdvancedProperty';
import { ChargingStationConfiguration } from '../models/ChargingStationConfiguration';
import { DisplayConfiguration } from '../models/displayConfiguration/DisplayConfiguration';
import { AmperageConfiguration } from '../models/AmperageConfiguration';

export const GetStationConfiguration =
  (configuration: ChargingStationConfiguration) => async (dispatch: Dispatch<ConfigurationActionTypes>) => {
    if (configuration) {
      dispatch({
        type: GET_CONFIGURATION_SUCCESS,
        chargingStationInfo: configuration.ChargingStationInfo,
        chargingStationPricing: configuration.ChargingStationPricing,
        displayConfiguration: configuration.DisplayConfiguration,
        advancedComponents: configuration.AdvancedConfigurationComponents,
        amperageConfiguration: configuration.AmperageConfiguration,
      });
    } else {
      dispatch({ type: GET_CONFIGURATION_FAIL });
    }
  };

export const EditConfigurationInfo =
  (info: ChargingStationInfo) => async (dispatch: Dispatch<ConfigurationActionTypes>) => {
    dispatch({ type: SET_CONFIGURATION, chargingStationInfo: info });
  };

export const SetAdvancedConfiguration =
  (advancedComponents: AdvancedConfigurationComponent[]) => (dispatch: Dispatch<ConfigurationActionTypes>) => {
    dispatch({ type: SET_ADVANCED_CONFIGURATION, advancedComponents });
  };

export const SetDisplayConfiguration =
  (displayConfiguration: DisplayConfiguration) => (dispatch: Dispatch<ConfigurationActionTypes>) => {
    dispatch({ type: SET_DISPLAY_CONFIGURATION, displayConfiguration });
  };

export const SetAmperage =
  (amperageConfiguration: AmperageConfiguration) => (dispatch: Dispatch<ConfigurationActionTypes>) => {
    dispatch({ type: SET_AMPERAGE, amperageConfiguration });
  };

export const SetAdvancedProperty =
  (advancedProperty: AdvancedProperty) => (dispatch: Dispatch<ConfigurationActionTypes>) => {
    dispatch({ type: SET_ADVANCED_PROPERTY, advancedProperty });
  };

export const SetDisplayedCategories =
  (selectedTab: ConfigurationProfiles, chargingStationInfo?: ChargingStationInfo) =>
  (dispatch: Dispatch<ConfigurationActionTypes>) => {
    const showFirmware = chargingStationInfo?.Level === 2;

    switch (selectedTab) {
      case ConfigurationProfiles.Simplified:
        dispatch({
          type: SET_DISPLAYED_CATEGORIES,
          displayedCategories: {
            showIdentification: true,
            showGeolocation: true,
            showPricing: true,
            showFirmware,
            showAdvanced: false,
          },
        });
        break;
      case ConfigurationProfiles.Advanced:
      default:
        dispatch({
          type: SET_DISPLAYED_CATEGORIES,
          displayedCategories: {
            showIdentification: true,
            showGeolocation: true,
            showPricing: true,
            showFirmware,
            showAdvanced: true,
          },
        });
    }
  };
