import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';
import { useParams } from 'react-router-dom';
import Configuration from '../../Configuration';

const BadRequest = (): ReactElement => {
  const { t } = useTranslation();
  const { message } = useParams<{ message: string }>();

  return (
    <Result
      status="warning"
      title={t('badRequestTitle')}
      subTitle={message}
      extra={
        <Button type="primary" href={Configuration.CSNMS_BASE_URL}>
          {t('goToCSNMS')}
        </Button>
      }
    />
  );
};

export default BadRequest;
