import { Owner } from '../models/Owner';

export const GET_OWNERS_SUCCESS = 'GET_OWNERS_SUCCESS';
export const GET_OWNERS_FAIL = 'GET_OWNERS_FAIL';

export interface GetOwnersSuccess {
  type: typeof GET_OWNERS_SUCCESS;
  owners: Owner[];
}

export interface GetOwnersFail {
  type: typeof GET_OWNERS_FAIL;
}

export type OwnerActionTypes = GetOwnersSuccess | GetOwnersFail;
