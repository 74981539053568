/* eslint-disable react/jsx-no-bind */
import React, { useEffect, ChangeEvent, FC } from 'react';
import { Button, PageHeader, Space, Table, Dropdown, Menu, Checkbox } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Owner } from '../../../owners/models/Owner';
import { Park } from '../../../parks/models/Park';
import { GetUsers, SetOwnerNames, SetEmail, SetRoleNames, SetParkNames, SetShowInactive } from '../redux/UsersActions';
import OwnerSelector from '../../shared/components/OwnerSelector';
import Email from '../../shared/components/EmailSelector';
import RoleSelector from '../../shared/components/RoleSelector';
import { AppState } from '../../../global/redux/store';
import ParkSelector from '../../shared/components/ParkSelector';
import { ErrorNotification } from '../../../shared/helper/NotificationHelper';
import { User } from '../models/User';
import ExpandableItemsList from '../../shared/components/ExpandableItemsList';
import './users.scss';

const Users: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userState = useSelector((state: AppState) => state.users);
  const editUserUrl = '/users/';

  useEffect(() => {
    const usersLoaded = userState.users.length > 0;
    dispatch(GetUsers(userState.showInactiveUsers, usersLoaded));
  }, [dispatch, userState.showInactiveUsers, userState.users.length]);

  useEffect(() => {
    if (userState.fetchingFailed) {
      ErrorNotification(t('userListError'));
    }
  }, [userState.fetchingFailed, t]);

  const onChange = (e: { target: { checked: boolean } }): void => {
    const showInactiveUsers = e.target.checked;
    dispatch(SetShowInactive(showInactiveUsers));
    dispatch(GetUsers(showInactiveUsers));
  };

  function onOwnersChange(value: string[]): void {
    dispatch(SetOwnerNames(value));
  }
  function onEmailsChange(event: ChangeEvent<HTMLInputElement>): void {
    dispatch(SetEmail(event.target.value));
  }
  function onRolesChange(value: string[]): void {
    dispatch(SetRoleNames(value));
  }

  function onParksChange(value: string[]): void {
    dispatch(SetParkNames(value));
  }
  const columns = [
    {
      title: t('email'),
      dataIndex: 'Email',
      key: 'Email',
      width: '30%',
      render: (email: string, record: User) => (
        <div>
          {email}
          {userState.showInactiveUsers ? (
            <div>
              <span className="user-active-text">{record.AccountEnabled ? t('active') : t('inactive')}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: t('roles'),
      key: 'Roles',
      dataIndex: 'Roles',
      render: (roles: string[]) => (
        <div>
          {roles.map((role) => (
            <div key={role}>{role}</div>
          ))}
        </div>
      ),
      width: '15%',
    },
    {
      title: t('owners'),
      key: 'Owners',
      dataIndex: 'Owners',
      render: (owners: Owner[]) => <ExpandableItemsList items={owners.map((x) => x.Description)} />,
      width: '25%',
    },
    {
      title: t('parks'),
      key: 'Parks',
      dataIndex: 'Parks',
      render: (parks: Park[]) => <ExpandableItemsList items={parks.map((x) => x.Description)} />,
      width: '25%',
    },
    {
      title: '',
      dataIndex: 'UserId',
      key: 'Actions',
      width: '5%',
      render: (UserId: string) => (
        <Space size="middle">
          <Link to={editUserUrl + UserId}>{t('edit')}</Link>
        </Space>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Checkbox onChange={onChange} checked={userState.showInactiveUsers}>
          {t('inactiveUsersText')}
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="user-list">
      <div>
        <PageHeader
          className="header"
          title={t('manageUsers')}
          subTitle=""
          extra={[
            <Space key="1" size="middle">
              <Button className="btn-create-user" type="primary">
                <Link to="/users/new">{t('btnCreateUser')}</Link>
              </Button>
              <Dropdown overlay={menu}>
                <MoreOutlined rotate={90} />
              </Dropdown>
            </Space>,
          ]}
        />
      </div>
      <div className="section">
        <div className="filters">
          <Email onChange={onEmailsChange} />
          <RoleSelector showTitle onChange={onRolesChange} />
          <OwnerSelector onChange={onOwnersChange} />
          <ParkSelector onChange={onParksChange} />
          <div />
        </div>
        <Table
          rowClassName="antd-table-row"
          className="user-table"
          loading={userState.loading}
          dataSource={userState.filteredUsers}
          columns={columns}
          rowKey={(user) => user.UserId}
          scroll={{ x: '100%' }}
        />
      </div>
    </div>
  );
};

export default Users;
