import { Dispatch } from 'redux';
import { GetOwnersRequest } from '../api/OwnerRequests';
import { GET_OWNERS_FAIL, GET_OWNERS_SUCCESS, OwnerActionTypes } from './OwnerActionTypes';

export const GetOwners = () => async (dispatch: Dispatch<OwnerActionTypes>) => {
  const owners = await GetOwnersRequest();

  if (owners.length > 0) {
    dispatch({
      type: GET_OWNERS_SUCCESS,
      owners,
    });
  } else {
    dispatch({
      type: GET_OWNERS_FAIL,
    });
  }
};
