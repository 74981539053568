import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationState } from '../../../redux/ConfigurationState';
import ConfigurationCategory from '../../ConfigurationCategory';
import { DisplayConfigurationData } from './DisplayConfigurationData';
import { useIsInRole } from '../../../helpers/UseIsInRole';
import { Roles } from '../../../../../shared/enums/Roles';

interface DisplayConfigurationProps {
  configurationState: ConfigurationState;
}

export const DisplayConfigurationCategory = (props: DisplayConfigurationProps): ReactElement => {
  const { t } = useTranslation();

  const isEditAllowed = useIsInRole([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.OPERATOR, Roles.OWNER_OPERATOR]);

  if (
    props.configurationState.displayConfiguration !== undefined &&
    props.configurationState.chargingStationInfo !== undefined
  ) {
    return (
      <ConfigurationCategory
        id="displayConfiguration"
        categoryTitle={t('displayConfiguration')}
        CategoryData={
          <DisplayConfigurationData
            displayConfiguration={props.configurationState.displayConfiguration}
            isEditAllowed={isEditAllowed}
            stationId={props.configurationState.chargingStationInfo.ChargingStationId}
          />
        }
      />
    );
  }
  return <div />;
};
