import React, { ReactElement, useState } from 'react';
import { Button, Card, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { Guid } from 'guid-typescript';
import { DisplayConfiguration } from '../../../models/displayConfiguration/DisplayConfiguration';
import { DisplayConfigurationMessage } from '../../../models/displayConfiguration/DisplayConfigurationMessage';
import { DisplayConfigurationDrawer } from './drawer/DisplayConfigurationDrawer';
import { LabeledField } from '../../../../../shared/components/forms/LabeledField';
import { useDisplayConfigurationMessageTitle } from '../../../helpers/DisplayConfigurationHelper';

interface DisplayConfigurationProps {
  displayConfiguration: DisplayConfiguration;
  isEditAllowed: boolean;
  stationId: string;
}

interface DisplayConfigurationLanguageProps {
  title: string;
  messages: DisplayConfigurationMessage[];
}

interface DisplayConfigurationMessageProps {
  message: DisplayConfigurationMessage;
  index: number;
}

const DisplayConfigurationMessageSection = (props: DisplayConfigurationMessageProps): ReactElement => {
  const title = useDisplayConfigurationMessageTitle(props.index);
  const { t } = useTranslation();

  return (
    <div>
      <Card type="inner" title={title} style={{ marginBottom: '1rem' }}>
        <LabeledField align="left" label={t('line1')} value={props.message.Line1} />
        <LabeledField align="left" label={t('line2')} value={props.message.Line2} />
        <LabeledField align="left" label={t('delay')} value={props.message.Delay.toString()} />
      </Card>
    </div>
  );
};

const DisplayConfigurationLanguage = (props: DisplayConfigurationLanguageProps): ReactElement => {
  return (
    <div>
      <div>
        <Card title={props.title} style={{ marginBottom: '1rem' }}>
          {props.messages.map((m, index) => (
            <DisplayConfigurationMessageSection key={Guid.create().toString()} index={index} message={m} />
          ))}
        </Card>
      </div>
    </div>
  );
};

export const DisplayConfigurationData = (props: DisplayConfigurationProps): ReactElement => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const showDrawer = (): void => {
    setVisible(true);
  };
  const onClose = (): void => {
    setVisible(false);
  };

  return (
    <div>
      <Drawer width="40rem" visible={visible} onClose={onClose}>
        <DisplayConfigurationDrawer
          displayConfiguration={props.displayConfiguration}
          stationId={props.stationId}
          onClose={onClose}
        />
      </Drawer>
      <DisplayConfigurationLanguage messages={props.displayConfiguration.Lang1.slice(2)} title={t('lang1')} />
      <DisplayConfigurationLanguage messages={props.displayConfiguration.Lang2.slice(2)} title={t('lang2')} />

      {props.isEditAllowed && (
        <div className="edit-configuration-button">
          <Button className="secondary-button" size="large" type="primary" onClick={showDrawer}>
            {t('edit')}
          </Button>
        </div>
      )}
    </div>
  );
};
