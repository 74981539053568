import { useSelector } from 'react-redux';
import { AppState } from '../../../global/redux/store';
import { Roles } from '../../../shared/enums/Roles';

export const useIsUserAdmin = (): boolean => {
  const user = useSelector((state: AppState) => state.currentUser);
  const roles = user.user?.Roles;

  const adminRoles = [Roles.ADMIN, Roles.SUPER_ADMIN, Roles.IT_ADMIN];
  return roles !== undefined && roles.filter((value) => adminRoles.includes(value)).length > 0;
};
