import { Dispatch } from 'redux';
import {
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  SET_OWNER_NAMES,
  UPDATE_FILTERED_LIST,
  UserActionTypes,
  USERS_LOADING,
  SET_EMAIL,
  SET_ROLES,
  SET_PARK_NAMES,
  SET_SHOW_INACTIVE,
  CLEAR_FILTERS,
} from './UserActionTypes';
import { GetUsersRequest } from '../UserRequests';

export const GetUsers =
  (getInactiveUsers: boolean, usersLoaded = false) =>
  async (dispatch: Dispatch<UserActionTypes>) => {
    if (!usersLoaded) {
      dispatch({ type: USERS_LOADING });
    } else {
      dispatch({ type: CLEAR_FILTERS });
    }

    const users = await GetUsersRequest(getInactiveUsers);

    if (users.length > 0) {
      dispatch({
        type: GET_USERS_SUCCESS,
        users: users.sort((a, b) => a.Email.localeCompare(b.Email)),
      });
      dispatch({ type: UPDATE_FILTERED_LIST });
    } else {
      dispatch({
        type: GET_USERS_FAIL,
      });
    }
  };

export const SetOwnerNames = (ownerNames: string[]) => (dispatch: Dispatch<UserActionTypes>) => {
  dispatch({ type: SET_OWNER_NAMES, ownerNames });
  dispatch({ type: UPDATE_FILTERED_LIST });
};

export const SetEmail = (value: string) => (dispatch: Dispatch<UserActionTypes>) => {
  dispatch({ type: SET_EMAIL, email: value });
  dispatch({ type: UPDATE_FILTERED_LIST });
};

export const SetRoleNames = (roles: string[]) => (dispatch: Dispatch<UserActionTypes>) => {
  dispatch({ type: SET_ROLES, roles });
  dispatch({ type: UPDATE_FILTERED_LIST });
};
export const SetParkNames = (parkNames: string[]) => (dispatch: Dispatch<UserActionTypes>) => {
  dispatch({ type: SET_PARK_NAMES, parkNames });
  dispatch({ type: UPDATE_FILTERED_LIST });
};

export const SetShowInactive = (showInactivesUsers: boolean) => (dispatch: Dispatch<UserActionTypes>) => {
  dispatch({ type: SET_SHOW_INACTIVE, showInactiveUsers: showInactivesUsers });
};
