import { ApiUser } from '../models/ApiUser';

export const GET_API_USERS_SUCCESS = 'GET_API_USERS_SUCCESS';
export const GET_API_USERS_FAIL = 'GET_API_USERS_FAIL';
export const API_USERS_LOADING = 'API_USERS_LOADING';
export const SET_PARK_NAME_FILTER = 'SET_PARK_NAME_FILTER';
export const SET_OWNER_NAME_FILTER = 'SET_OWNER_NAME_FILTER';
export const SET_NAME_FILTER = 'SET_NAME_FILTER';
export const UPDATE_FILTERED_LIST = 'UPDATE_FILTERED_LIST';

export interface GetApiUsersSuccess {
  type: typeof GET_API_USERS_SUCCESS;
  apiUsers: ApiUser[];
}

export interface ApiUsersLoading {
  type: typeof API_USERS_LOADING;
}

export interface GetUsersFail {
  type: typeof GET_API_USERS_FAIL;
}

export interface SetParkNameFilter {
  type: typeof SET_PARK_NAME_FILTER;
  parkNames: string[];
}

export interface SetOwnerNameFilter {
  type: typeof SET_OWNER_NAME_FILTER;
  ownerNames: string[];
}

export interface SetNameFilter {
  type: typeof SET_NAME_FILTER;
  name: string;
}

export interface UpdateFilteredList {
  type: typeof UPDATE_FILTERED_LIST;
}

export type ApiUserActionTypes =
  | GetApiUsersSuccess
  | ApiUsersLoading
  | GetUsersFail
  | SetParkNameFilter
  | SetOwnerNameFilter
  | SetNameFilter
  | UpdateFilteredList;
