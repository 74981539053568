import React, { useEffect, ChangeEvent, FC } from 'react';
import { Button, Input, PageHeader, Space, Table } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Owner } from '../../../owners/models/Owner';
import { Park } from '../../../parks/models/Park';
import OwnerSelector from '../../shared/components/OwnerSelector';
import { AppState } from '../../../global/redux/store';
import ParkSelector from '../../shared/components/ParkSelector';
import { ErrorNotification } from '../../../shared/helper/NotificationHelper';
import { GetApiUsers, SetName, SetOwnerNames, SetParkNames } from '../redux/ApiUsersActions';
import '../../users/pages/users.scss';

const ApiUsers: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiUserState = useSelector((state: AppState) => state.apiUsers);
  const editApiUserUrl = '/apiusers/';

  useEffect(() => {
    dispatch(GetApiUsers());
    if (apiUserState.fetchingFailed) {
      ErrorNotification(t('userListError'));
    }
  }, [apiUserState.fetchingFailed, dispatch, t]);

  const onOwnersChange = (value: string[]): void => {
    dispatch(SetOwnerNames(value));
  };
  const onNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(SetName(event.target.value));
  };

  const onParksChange = (value: string[]): void => {
    dispatch(SetParkNames(value));
  };

  const columns = [
    {
      title: t('name'),
      key: 'DisplayName',
      dataIndex: 'DisplayName',
      width: '10%',
    },
    {
      title: t('subscriptionId'),
      key: 'SubscriptionName',
      dataIndex: 'SubscriptionName',
      width: '20%',
    },
    {
      title: t('owners'),
      key: 'Owners',
      dataIndex: 'Owners',
      render: (owners: Owner[]) => (
        <div>
          {owners.map((owner) => (
            <div className="list-column-element" key={owner.OwnerId}>
              {owner.Description}
            </div>
          ))}
        </div>
      ),
      width: '25%',
    },
    {
      title: t('parks'),
      key: 'Parks',
      dataIndex: 'Parks',
      render: (parks: Park[]) => (
        <div>
          {parks.map((park) => (
            <div className="list-column-element" key={park.ParkId}>
              {park.Description}
            </div>
          ))}
        </div>
      ),
      width: '25%',
    },
    {
      title: '',
      dataIndex: 'UserId',
      key: 'Actions',
      width: '5%',
      render: (UserId: string) => (
        <Space size="middle">
          <Link to={editApiUserUrl + UserId}>{t('edit')}</Link>
        </Space>
      ),
    },
  ];

  return (
    <div className="user-list">
      <div>
        <PageHeader
          className="header"
          title={t('manageApiUsers')}
          subTitle=""
          extra={[
            <Space key="1" size="middle">
              <Button className="btn-create-user" type="primary">
                <Link to="/apiusers/new">{t('btnCreateApi')}</Link>
              </Button>
              <MoreOutlined rotate={90} />
            </Space>,
          ]}
        />
        <div />
      </div>
      <div className="section">
        <div className="filters">
          <div>
            <div className="filter-name">{t('name')}</div>
            <Input.Search
              type="text"
              className="medium-input-spaced"
              placeholder={t('namePlaceholder')}
              onChange={onNameChange}
            />
          </div>
          <OwnerSelector onChange={onOwnersChange} />
          <ParkSelector onChange={onParksChange} />
        </div>
        <Table
          rowClassName="antd-table-row"
          className="user-table"
          loading={apiUserState.loading}
          dataSource={apiUserState.filteredApiUsers}
          columns={columns}
          rowKey={(user) => user.SubscriptionName}
          scroll={{ x: '100%' }}
        />
      </div>
    </div>
  );
};

export default ApiUsers;
