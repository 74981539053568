import { OwnersState } from './OwnersState';
import { GET_OWNERS_FAIL, GET_OWNERS_SUCCESS, OwnerActionTypes } from './OwnerActionTypes';

const initialState: OwnersState = {
  owners: [],
  fetchingFailed: false,
};

const OwnersReducer = (state = initialState, action: OwnerActionTypes): OwnersState => {
  switch (action.type) {
    case GET_OWNERS_FAIL:
      return { fetchingFailed: true };
    case GET_OWNERS_SUCCESS:
      return { owners: action.owners, fetchingFailed: false };

    default:
      return state;
  }
};

export { OwnersReducer };
