import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { UserActivity } from '../../users/models/UserActivity';
import { GetUserActivityLogs } from '../../users/UserRequests';

type ActivityLogProps = {
  UserId: string;
};

const UserActivityLog: FC<ActivityLogProps> = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('date'),
      dataIndex: 'Date',
      key: 'Date',
      width: '12rem',
    },
    {
      title: t('activity'),
      dataIndex: 'ActivityType',
      key: 'ActivityType',
      width: '16rem',
      render: (activityType: string) => <div>{t(activityType)}</div>,
    },
    {
      title: t('status'),
      dataIndex: 'Status',
      key: 'Status',
      render: (status: string) => <div>{t(status)}</div>,
    },
  ];

  useEffect(() => {
    async function getActivityLogs(): Promise<void> {
      const activityLogs = await GetUserActivityLogs(props.UserId);
      setActivityLogs(activityLogs);
      setIsLoading(false);
    }
    getActivityLogs();
  }, [props.UserId]);

  const [activityLogs, setActivityLogs] = useState([] as UserActivity[]);
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div className="activity-log-section">
      <h3>{t('activityLogTitle')}</h3>
      <Table
        locale={{ emptyText: t('emptyText') }}
        loading={isLoading}
        dataSource={activityLogs}
        columns={columns}
        pagination={{ defaultPageSize: 5 }}
      />
    </div>
  );
};

export default UserActivityLog;
