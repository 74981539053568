import { User } from '../models/User';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const ADD_USER = 'ADD_USER';
export const USERS_LOADING = 'USERS_LOADING';
export const SET_OWNER_NAMES = 'SET_OWNER_NAMES';
export const UPDATE_FILTERED_LIST = 'UPDATE_FILTERED_LIST';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_ROLES = 'SET_ROLES';
export const SET_PARK_NAMES = 'SET_PARK_NAMES';
export const SET_SHOW_INACTIVE = 'SET_SHOW_INACTIVE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export interface GetUsersSuccess {
  type: typeof GET_USERS_SUCCESS;
  users: User[];
}

export interface UsersLoading {
  type: typeof USERS_LOADING;
}

export interface GetUsersFail {
  type: typeof GET_USERS_FAIL;
}

export interface SetOwnerNames {
  type: typeof SET_OWNER_NAMES;
  ownerNames: string[];
}

export interface AddUserAction {
  type: typeof ADD_USER;
  user: User;
}

export interface UpdateFilteredList {
  type: typeof UPDATE_FILTERED_LIST;
}

export interface SetEmail {
  type: typeof SET_EMAIL;
  email: string;
}
export interface SetRoles {
  type: typeof SET_ROLES;
  roles: string[];
}

export interface SetParkNames {
  type: typeof SET_PARK_NAMES;
  parkNames: string[];
}
export interface SetShowInactive {
  type: typeof SET_SHOW_INACTIVE;
  showInactiveUsers: boolean;
}

export interface ClearFilters {
  type: typeof CLEAR_FILTERS;
}

export type UserActionTypes =
  | GetUsersSuccess
  | AddUserAction
  | UsersLoading
  | SetOwnerNames
  | UpdateFilteredList
  | GetUsersFail
  | SetEmail
  | SetRoles
  | SetParkNames
  | ClearFilters
  | SetShowInactive;
