import React, { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Routing from './global/routes/Routing';
import './global/i18n';
import Configuration from './global/Configuration';
import './global/style/global.scss';

const App: FC = () => (
  <HelmetProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{Configuration.TITLE}</title>
      <link rel="icon" type="image/x-icon" href={Configuration.FAVICON} />
    </Helmet>
    <Routing />
  </HelmetProvider>
);

export default App;
