export enum Roles {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super-admin',
  OPERATOR = 'operator',
  OWNER_OPERATOR = 'owneroperator',
  SUPPORT = 'support',
  SUPPORT_NOC = 'support-noc',
  READONLY_PARK_OPERATOR = 'read-only-park-operator',
  READONLY_OPERATOR = 'read-only-owner-operator',
  IT_ADMIN = 'it-admin'
}
