import { ChargingStationInfo } from '../models/ChargingStationInfo';
import { ChargingStationPricing } from '../models/pricing/ChargingStationPricing';
import { DisplayedCategories } from '../models/DisplayedCategories';
import { AdvancedConfigurationComponent } from '../models/AdvancedConfigurationComponent';
import { AdvancedProperty } from '../models/AdvancedProperty';
import { DisplayConfiguration } from '../models/displayConfiguration/DisplayConfiguration';
import { AmperageConfiguration } from '../models/AmperageConfiguration';

export const GET_CONFIGURATION_SUCCESS = 'GET_CONFIGURATION_SUCCESS';
export const GET_CONFIGURATION_FAIL = 'GET_CONFIGURATION_FAIL';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_PRICING = 'SET_PRICING';
export const SET_DISPLAYED_CATEGORIES = 'SET_DISPLAYED_CATEGORIES';
export const SET_ADVANCED_CONFIGURATION = 'SET_ADVANCED_CONFIGURATION';
export const SET_ADVANCED_PROPERTY = 'SET_ADVANCED_PROPERTY';
export const SET_DISPLAY_CONFIGURATION = 'SET_DISPLAY_CONFIGURATION';
export const SET_AMPERAGE = 'SET_AMPERAGE';

export interface GetConfigurationSuccess {
  type: typeof GET_CONFIGURATION_SUCCESS;
  chargingStationInfo: ChargingStationInfo;
  chargingStationPricing: ChargingStationPricing;
  displayConfiguration: DisplayConfiguration;
  advancedComponents: AdvancedConfigurationComponent[];
  amperageConfiguration: AmperageConfiguration;
}

export interface SetConfiguration {
  type: typeof SET_CONFIGURATION;
  chargingStationInfo: ChargingStationInfo;
}

export interface GetConfigurationFail {
  type: typeof GET_CONFIGURATION_FAIL;
}

export interface SetPricing {
  type: typeof SET_PRICING;
  pricing: ChargingStationPricing;
}

export interface SetDisplayedCategories {
  type: typeof SET_DISPLAYED_CATEGORIES;
  displayedCategories: DisplayedCategories;
}

export interface SetAdvancedConfiguration {
  type: typeof SET_ADVANCED_CONFIGURATION;
  advancedComponents: AdvancedConfigurationComponent[];
}

export interface SetAdvancedProperty {
  type: typeof SET_ADVANCED_PROPERTY;
  advancedProperty: AdvancedProperty;
}

export interface SetDisplayConfiguration {
  type: typeof SET_DISPLAY_CONFIGURATION;
  displayConfiguration: DisplayConfiguration;
}

export interface SetAmperageConfiguration {
  type: typeof SET_AMPERAGE;
  amperageConfiguration: AmperageConfiguration;
}

export type ConfigurationActionTypes =
  | GetConfigurationSuccess
  | GetConfigurationFail
  | SetConfiguration
  | SetPricing
  | SetDisplayedCategories
  | SetAdvancedConfiguration
  | SetAdvancedProperty
  | SetDisplayConfiguration
  | SetAmperageConfiguration;
