import React, { FC, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Identification from '../components/categories/Identification';
import Geolocation from '../components/categories/Geolocation';
import { GetStationConfiguration, SetDisplayedCategories } from '../redux/ConfigurationActions';
import { AppState } from '../../../global/redux/store';
import { GetConfigurationRequest } from '../api/ConfigurationRequests';
import { ConfigurationProfiles } from '../../../shared/enums/ConfigurationProfiles';
import { Pricing } from '../components/categories/Pricing';
import ConfigurationContentSideMenu from '../components/ConfigurationContentSideMenu';
import './stationConfiguration.scss';
import { AdvancedConfiguration } from '../components/advanced/AdvancedConfiguration';
import { FactoryReset } from '../components/FactoryReset';
import { ApiErrors } from '../../../shared/enums/ApiErrors';
import { ConfigurationActions } from '../components/ConfigurationActions';
import { DisplayConfigurationCategory } from '../components/categories/displayConfiguration/DisplayConfiguration';
import { Firmware } from '../components/categories/Firmware';
import { AmperageCategory } from '../components/categories/AmperageCategory';
import { ConfigurationProfileMenu } from '../components/ConfigurationProfileMenu';

const StationConfiguration: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const badRequestUrl = '/400';
  const notFoundUrl = '/404';
  const unexpectedUrl = '/500';

  const { stationId } = useParams<{ stationId: string }>();

  const configurationState = useSelector((state: AppState) => state.configuration);

  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    dispatch(SetDisplayedCategories(ConfigurationProfiles.Simplified));

    const GetConfigurationInner = async (): Promise<void> => {
      const configuration = await GetConfigurationRequest(stationId);
      setIsPageLoading(false);
      if (configuration.isLeft()) {
        if (configuration.value === ApiErrors.NotFound) {
          history.push(notFoundUrl);
        } else if (configuration.value === ApiErrors.BadRequest) {
          history.push(`${badRequestUrl}/${t('firmwareNotFoundError')}`);
        } else {
          history.push(unexpectedUrl);
        }
      } else {
        dispatch(GetStationConfiguration(configuration.value));
        dispatch(SetDisplayedCategories(ConfigurationProfiles.Simplified, configuration.value.ChargingStationInfo));
      }
    };

    GetConfigurationInner();
  }, [dispatch, history, stationId, t]);

  return (
    <div className="configuration-page">
      <ConfigurationProfileMenu configurationState={configurationState} />

      <ConfigurationContentSideMenu configurationState={configurationState} />

      <div className="main-configuration-section">
        <div className="station-information">
          <h1 className="configuration-title">{t('stationInformation')}</h1>

          {isPageLoading && <Skeleton active />}

          <Identification configurationState={configurationState} />
          <Geolocation configurationState={configurationState} />
          <Firmware configurationState={configurationState} stationId={stationId} />
          <Pricing configurationState={configurationState} />
          <DisplayConfigurationCategory configurationState={configurationState} />
          <AmperageCategory configurationState={configurationState} />
        </div>

        <FactoryReset stationId={stationId} />

        <AdvancedConfiguration stationId={stationId} configurationState={configurationState} />
      </div>
      <ConfigurationActions stationId={stationId} />
    </div>
  );
};

export default StationConfiguration;
