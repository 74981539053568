import React, { ReactElement, useState } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ErrorNotification, InfoNotification, SuccessNotification } from '../../../shared/helper/NotificationHelper';
import { ApplyConfigurationToStationRequest, EditConfigurationRequest } from '../api/ConfigurationRequests';
import { RequestStatus } from '../enums/RequestStatus';
import { AppState } from '../../../global/redux/store';
import { useIsInRole } from '../helpers/UseIsInRole';
import { Roles } from '../../../shared/enums/Roles';

interface ConfigurationActionsProps {
  stationId: string;
}

export const ConfigurationActions = (props: ConfigurationActionsProps): ReactElement => {
  const { t } = useTranslation();
  const [configurationActionLoading, setConfigurationActionLoading] = useState(false);
  const { confirm } = Modal;

  const configurationState = useSelector((state: AppState) => state.configuration);

  const isVisible = useIsInRole([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.OPERATOR, Roles.OWNER_OPERATOR]);

  const SaveConfigurationInCSNMS = async (): Promise<void> => {
    if (configurationState.chargingStationInfo !== undefined && configurationState.displayConfiguration !== undefined) {
      InfoNotification(t('saveConfigurationNotif'));
      setConfigurationActionLoading(true);
      const configuration = await EditConfigurationRequest(
        configurationState.chargingStationInfo,
        configurationState.displayConfiguration,
        configurationState.amperageConfiguration,
        configurationState.advancedComponents,
        props.stationId
      );
      setConfigurationActionLoading(false);
      if (configuration.isLeft()) {
        ErrorNotification(t('editConfigError'));
      } else {
        SuccessNotification(t('editConfigSuccess'));
      }
    }
  };

  const ApplyConfigurationToStation = async (): Promise<void> => {
    confirm({
      title: t('confirmation'),
      content: t('applyConfigurationWarning'),
      onOk() {
        ApplyConfigurationAction();
        return null;
      },
      onCancel() {
        return null;
      },
    });

    const ApplyConfigurationAction = async (): Promise<void> => {
      InfoNotification(t('saveConfigurationNotif'));
      setConfigurationActionLoading(true);
      const result = await ApplyConfigurationToStationRequest(props.stationId);
      setConfigurationActionLoading(false);
      if (result.isRight() && result.value === RequestStatus.Completed) {
        SuccessNotification(t('applyConfigurationSuccess'));
      } else {
        ErrorNotification(t('applyConfigurationError'));
      }
    };
  };

  if (isVisible) {
    return (
      <div className="actions configuration-actions">
        <Button
          loading={configurationActionLoading}
          className="secondary-button"
          size="large"
          type="primary"
          onClick={SaveConfigurationInCSNMS}
        >
          {t('saveConfigBtn')}
        </Button>
        <Button
          loading={configurationActionLoading}
          className="apply-button"
          size="large"
          type="primary"
          onClick={ApplyConfigurationToStation}
        >
          {t('applyChangesBtn')}
        </Button>
      </div>
    );
  }
  return <div />;
};
