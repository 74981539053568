import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { RootStore } from './global/redux/store';
import ErrorBoundary from './global/api/ErrorHandler';
import Unexpected from './global/pages/error/Unexpected';

ReactDOM.render(
  <Provider store={RootStore}>
    <ErrorBoundary renderError={() => <Unexpected />}>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
