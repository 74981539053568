import React, { ChangeEvent, FC } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

type EmailSelectorComponentProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const EmailSelector: FC<EmailSelectorComponentProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="filter-name">{t('email')}</div>
      <Input
        type="text"
        className="medium-input-spaced"
        placeholder={t('placeHolderEmail')}
        onChange={props.onChange}
      />
    </div>
  );
};

export default EmailSelector;
