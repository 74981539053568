import React, { FC, useEffect, FocusEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../../../global/redux/store';
import { GetParks } from '../../../../parks/redux/ParksActions';
import { GetOwners } from '../../../../owners/redux/OwnersActions';
import { EditConfigurationInfo } from '../../redux/ConfigurationActions';
import { ChargingStationInfo } from '../../models/ChargingStationInfo';
import { LabeledInputText, LabeledSelect } from '../../../../shared/components/forms';
import { OptionProps } from '../../../../shared/components/forms/LabeledSelect';
import { Park } from '../../../../parks/models/Park';
import { requiredWithMessage } from '../../helpers/formValidation';
import { LabeledField } from '../../../../shared/components/forms/LabeledField';
import { MacAddress, ConvertMacAddressToGuid } from './MacAddress';

interface IdentificationProps {
  stationInfo: ChargingStationInfo;
  isReadonly: boolean;
}

const IdentificationData: FC<IdentificationProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parks = useSelector((state: AppState) => state.parks.parks);
  const [form] = Form.useForm();
  const { isReadonly } = props;

  useEffect(() => {
    dispatch(GetParks());
    dispatch(GetOwners());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      Description: props.stationInfo.Description,
      ModelName: props.stationInfo.ModelName,
      ParkName: props.stationInfo.ParkName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateForm = (e: FocusEvent<HTMLFormElement>): void => {
    const updatedStationInfos = { ...props.stationInfo, [e.target.id]: form.getFieldValue(e.target.id) };

    if (e.target.id === 'ParkName') {
      const selectedPark = getSelectedPark(form.getFieldValue(e.target.id));
      if (selectedPark !== undefined) {
        updatedStationInfos.ParkName = selectedPark.Description;
        updatedStationInfos.ParkId = selectedPark.ParkId;
        updatedStationInfos.OwnerName = selectedPark.OwnerName || '';
        dispatch(EditConfigurationInfo(updatedStationInfos));
      }
    }

    if (e.target.id === 'MacAddress') {
      updatedStationInfos.MacAddress = ConvertMacAddressToGuid(updatedStationInfos.MacAddress);
    }

    dispatch(EditConfigurationInfo(updatedStationInfos));
  };

  const getSelectedPark = (parkId: string): Park | undefined => {
    return parks?.find((park: Park) => park.ParkId === parkId);
  };

  const getParkOptions = (parks: Park[]): OptionProps[] => {
    return parks?.map((park: Park) => ({ label: park.Description, value: park.ParkId }));
  };

  const Description = (): ReactElement => {
    if (!isReadonly) {
      return (
        <LabeledInputText
          label={t('chargingStation')}
          name="Description"
          validationRules={[requiredWithMessage(t('requiredFieldDescription'))]}
        />
      );
    }
    return <LabeledField label={t('chargingStation')} value={props.stationInfo.Description} />;
  };

  const Park = (): ReactElement => {
    if (!isReadonly) {
      return <LabeledSelect name="ParkName" label={t('park')} options={getParkOptions(parks as Park[])} />;
    }
    return <LabeledField label={t('park')} value={props.stationInfo.ParkName} />;
  };

  return (
    <div>
      <Form colon={false} form={form} onBlur={updateForm}>
        <Description />
        <LabeledField label={t('model')} value={props.stationInfo.ModelName} />
        <LabeledField label={t('owner')} value={props.stationInfo.OwnerName} />
        <Park />
        <MacAddress form={form} macAddressInitialValue={props.stationInfo.MacAddress} />
      </Form>
    </div>
  );
};

export default IdentificationData;
