import { Owner } from '../models/Owner';
import Configuration from '../../global/Configuration';
import HttpClient from '../../global/api/HttpClient';

export const GetOwnersRequest = async (): Promise<Owner[]> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/owners`);
    return response.data;
  } catch (ex) {
    return [];
  }
};
