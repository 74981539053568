import React, { FC, useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetParks } from '../../../parks/redux/ParksActions';
import { AppState } from '../../../global/redux/store';

type ParkSelectorComponentProps = {
  onChange: (parkNames: string[]) => void;
};

const ParkSelector: FC<ParkSelectorComponentProps> = (props) => {
  const parkState = useSelector((state: AppState) => state.parks);
  const { Option } = Select;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetParks());
  }, [dispatch]);

  return (
    <div>
      <div className="filter-name">{t('associatedParks')}</div>
      <Select
        mode="multiple"
        allowClear
        onChange={props.onChange}
        className="medium-input-spaced"
        placeholder={t('placeholderParkName')}
      >
        {parkState.parks?.map((park) => (
          <Option key={park.ParkId} value={park.Description} label={park.Description}>
            <div>{park.Description}</div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default ParkSelector;
