import { ParksState } from './ParksState';
import { GET_PARKS_FAIL, GET_PARKS_SUCCESS, ParkActionTypes } from './ParksActionTypes';

const initialState: ParksState = {
  parks: [],
  fetchingFailed: false,
};

const ParksReducer = (state = initialState, action: ParkActionTypes): ParksState => {
  switch (action.type) {
    case GET_PARKS_FAIL:
      return { fetchingFailed: true };
    case GET_PARKS_SUCCESS:
      return { parks: action.parks, fetchingFailed: false };

    default:
      return state;
  }
};

export { ParksReducer };
