import React, { ReactElement, useEffect } from 'react';
import { Card, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons/lib';
import { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import { useDisplayConfigurationMessageTitle } from '../../../../helpers/DisplayConfigurationHelper';
import { DisplayConfigurationMessage } from '../../../../models/displayConfiguration/DisplayConfigurationMessage';
import { LabeledInputText } from '../../../../../../shared/components/forms';
import { useValidateNumber } from '../../../../helpers/formValidation';

interface DisplayConfigurationMessageFieldProps {
  message: DisplayConfigurationMessage;
  index: number;
  changeMessage: (newMessage: DisplayConfigurationMessage) => void;
  removeMessage: () => void;
}

export const DisplayConfigurationMessageFields = (props: DisplayConfigurationMessageFieldProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = useForm();
  const title = useDisplayConfigurationMessageTitle(props.index);
  const validateNumber = useValidateNumber(0);

  useEffect(() => {
    form.setFieldsValue({
      Line1: props.message.Line1,
      Line2: props.message.Line2,
      Delay: props.message.Delay,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = (): void => {
    const newField = {
      Delay: form.getFieldValue('Delay'),
      Line1: form.getFieldValue('Line1'),
      Line2: form.getFieldValue('Line2'),
    };

    props.changeMessage(newField);
  };

  const validateMessageLine = (rule: Rule, value: string): Promise<void> => {
    if (value.length > 20) {
      return Promise.reject(t('displayConfigurationLineError'));
    }
    return Promise.resolve();
  };

  return (
    <div>
      <Card
        type="inner"
        title={title}
        style={{ marginBottom: '1rem' }}
        extra={<CloseOutlined onClick={props.removeMessage} />}
      >
        <Form form={form} onBlur={onBlur}>
          <LabeledInputText
            label={t('line1')}
            name="Line1"
            align="left"
            validationRules={[{ validator: validateMessageLine }]}
          />
          <LabeledInputText
            label={t('line2')}
            name="Line2"
            align="left"
            validationRules={[{ validator: validateMessageLine }]}
          />
          <LabeledInputText
            label={t('delay')}
            name="Delay"
            align="left"
            validationRules={[{ validator: validateNumber }]}
          />
        </Form>
      </Card>
    </div>
  );
};
