import React, { FC, useEffect, useState } from 'react';
import { Card, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons/lib';
import { AppState } from '../../../global/redux/store';
import { GetParks } from '../../../parks/redux/ParksActions';
import { Park } from '../../../parks/models/Park';

type LargeParkSelectorComponentProps = {
  onChange?: (parks: Park[]) => void;
  initialParks?: Park[];
};

const LargeParkSelector: FC<LargeParkSelectorComponentProps> = (props) => {
  const parkState = useSelector((state: AppState) => state.parks);
  const { Option } = Select;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedParks, setSelectedParks] = useState(props.initialParks || []);
  const [selected, setSelected] = useState(undefined);

  useEffect(() => {
    dispatch(GetParks());
  }, [dispatch]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  function onParksChange(parkName: string, parkInfo: any): void {
    if (parkInfo.key != null && selectedParks.find((s) => s.ParkId === parkInfo.key) === undefined) {
      const newSelectedParks = selectedParks.concat({ ParkId: parkInfo.key, Description: parkName });
      setSelectedParks(newSelectedParks);

      if (props.onChange !== undefined) {
        props.onChange(newSelectedParks);
      }
    }
    setSelected(undefined);
  }

  function removePark(park: Park): void {
    const newSelectedParks = selectedParks.filter((o) => o !== park);
    setSelectedParks(newSelectedParks);

    if (props.onChange !== undefined) {
      props.onChange(newSelectedParks);
    }
  }

  return (
    <Card
      title={t('associatedParks')}
      type="inner"
      className="large-selector"
      extra={
        <Select
          showSearch
          className="medium-input"
          value={selected}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={onParksChange}
          placeholder={t('AddPark')}
        >
          {parkState.parks?.map((park) => (
            <Option key={park.ParkId} value={park.Description} label={park.Description}>
              <div>{park.Description}</div>
            </Option>
          ))}
        </Select>
      }
    >
      {selectedParks.length === 0 && <div className="placeholder">{t('NoParkPlaceholder')}</div>}
      {selectedParks.map((park) => (
        <div key={park.ParkId} className="large-selector-element">
          <div>{park.Description}</div> <CloseOutlined onClick={() => removePark(park)} />
        </div>
      ))}
    </Card>
  );
};

export default LargeParkSelector;
