import { Dispatch } from 'redux';
import { GetParksRequest } from '../api/ParkRequests';
import { GET_PARKS_FAIL, GET_PARKS_SUCCESS, ParkActionTypes } from './ParksActionTypes';

export const GetParks = () => async (dispatch: Dispatch<ParkActionTypes>) => {
  const parks = await GetParksRequest();

  if (parks.length > 0) {
    dispatch({
      type: GET_PARKS_SUCCESS,
      parks,
    });
  } else {
    dispatch({
      type: GET_PARKS_FAIL,
    });
  }
};
