import React, { ReactElement } from 'react';
import TooltipLabel from './ToolTipLabel';

interface LabeledFieldProps {
  label: string;
  value: string;
  align?: string;
}

export const LabeledField = (props: LabeledFieldProps): ReactElement => {
  return (
    <div className="field-group">
      <TooltipLabel label={props.label} name={props.label} align={props.align} />
      <div className="field-value readonly-field">{props.value}</div>
    </div>
  );
};
