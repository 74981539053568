import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Breadcrumb, Divider } from 'antd';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LargeOwnerSelector from '../../../shared/components/LargeOwnerSelector';
import LargeParkSelector from '../../../shared/components/LargeParkSelector';
import { ErrorNotification, SuccessNotification } from '../../../../shared/helper/NotificationHelper';
import Loading from '../../../../global/pages/loading/Loading';
import { ApiUser } from '../../models/ApiUser';
import { GetApiUserRequest, PatchApiUserRequest } from '../../ApiUserRequests';
import '../../../users/pages/users.scss';

const EditApiUser: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const apiUserListUrl = '/apiusers';
  const notFoundUrl = '/404';
  const [form] = Form.useForm();
  const [initialApiUser, setInitialUser] = useState(undefined as ApiUser | undefined);

  const history = useHistory();

  useEffect(() => {
    async function getApiUser(): Promise<void> {
      const returnUser = await GetApiUserRequest(id);
      if (returnUser === undefined) {
        history.push(notFoundUrl);
      } else {
        setInitialUser(returnUser);
      }
    }
    getApiUser();
  }, [history, id, notFoundUrl]);

  const onFinish = async (values: ApiUser): Promise<void> => {
    const apiUser = values;
    apiUser.UserId = initialApiUser?.UserId || '';
    apiUser.SubscriptionName = initialApiUser?.SubscriptionName || '';
    const result = await PatchApiUserRequest(apiUser.UserId, apiUser);
    if (result === undefined) {
      ErrorNotification(t('editApiUserSaveError'));
    } else {
      SuccessNotification(t('editApiUserSuccess'));
      history.push(apiUserListUrl);
    }
  };

  return initialApiUser === undefined ? (
    <Loading />
  ) : (
    <div className="user-list">
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={apiUserListUrl}>{t('manageApiUsers')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('editUserInformation')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h2>{initialApiUser?.DisplayName}</h2>
      </div>
      <Form form={form} className="full-width" initialValues={initialApiUser} onFinish={onFinish} layout="vertical">
        <div className="section">
          <h3>{t('editApiTitle')}</h3>
          <Divider />
          <div className="filters">
            <Form.Item
              label={t('Name')}
              name="DisplayName"
              className="medium-input-spaced"
              rules={[{ required: true, message: t('requiredApiName') }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('subscriptionId')} name="SubscriptionName" className="medium-input-spaced">
              <Input disabled />
            </Form.Item>
          </div>
          <Divider />
          <Form.Item name="Owners">
            <LargeOwnerSelector initialOwners={initialApiUser?.Owners} />
          </Form.Item>
          <Form.Item name="Parks">
            <LargeParkSelector initialParks={initialApiUser?.Parks} />
          </Form.Item>
        </div>
        <div className="footer">
          <Form.Item>
            <Button type="link" danger>
              <Link to={apiUserListUrl}>{t('cancel')}</Link>
            </Button>
            <Button type="primary" className="edit-user-form-action-submit" htmlType="submit">
              {t('save')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EditApiUser;
