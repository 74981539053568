import React, { ReactElement } from 'react';
import { LeftCircleTwoTone } from '@ant-design/icons/lib';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import { SetAdvancedProperty } from '../../redux/ConfigurationActions';

interface StationValueSectionProps {
  compareModeEnabled: boolean;
  advancedProperty: AdvancedProperty;
}

export const StationValueSection = (props: StationValueSectionProps): ReactElement => {
  const dispatch = useDispatch();

  const applyStationValue = (): void => {
    const newAdvancedProperty = produce(props.advancedProperty, (draft) => {
      draft.PropertyValue = draft.StationValue;
    });

    dispatch(SetAdvancedProperty(newAdvancedProperty));
  };

  return (
    <div className="station-value">
      {props.compareModeEnabled && (
        <div style={{ padding: '4px' }}>
          <LeftCircleTwoTone onClick={applyStationValue} style={{ fontSize: '20px' }} />
          <span className="station-text">{props.advancedProperty.StationValue}</span>
        </div>
      )}
    </div>
  );
};
