import { Dispatch } from 'redux';
import { GetRolesRequest } from '../../api/RoleRequests';
import { GET_ROLES_FAIL, GET_ROLES_SUCCESS, RoleActionTypes } from './RoleActionTypes';

export const GetRoles = () => async (dispatch: Dispatch<RoleActionTypes>) => {
  const roles = await GetRolesRequest();

  if (roles.length > 0) {
    dispatch({
      type: GET_ROLES_SUCCESS,
      roles,
    });
  } else {
    dispatch({
      type: GET_ROLES_FAIL,
    });
  }
};
