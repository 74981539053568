import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Divider, Form, Popover, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons/lib';
import { useDispatch } from 'react-redux';
import { ChangeExpectedFirmware, GetExpectedFirmwares } from '../../api/ConfigurationRequests';
import { ErrorNotification, InfoNotification, SuccessNotification } from '../../../../shared/helper/NotificationHelper';
import { Firmware } from '../../models/Firmware';
import LabeledInputSelect from '../../../../shared/components/forms/LabeledSelect';
import { LabeledField } from '../../../../shared/components/forms/LabeledField';
import { EditConfigurationInfo } from '../../redux/ConfigurationActions';
import { ChargingStationInfo } from '../../models/ChargingStationInfo';

interface FirmwareDrawerProps {
  stationId: string;
  stationInfo: ChargingStationInfo;
}

export const FirmwareDrawer = (props: FirmwareDrawerProps): ReactElement => {
  const { t } = useTranslation();
  const [firmwares, setFirmwares] = useState([] as Firmware[]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [showChangeVersion, setShowChangeVersion] = useState(false);

  const changeVersionAction = (): void => {
    setShowChangeVersion(true);
  };

  const closePopover = (): void => {
    setShowChangeVersion(false);
  };

  useEffect(() => {
    const GetFirmwares = async (): Promise<void> => {
      form.setFieldsValue({ ExpectedFirmwareVersion: props.stationInfo.ExpectedFirmwareId });

      const firmwares = await GetExpectedFirmwares(props.stationId);
      if (firmwares.isLeft()) {
        ErrorNotification('errorGettingFirmwares');
      } else {
        setFirmwares(firmwares.value);
      }
    };

    GetFirmwares();
  }, [props.stationId, form, props]);

  const getFirmwareVersionById = (firmwareId: string): string => {
    const firmwareVersion = firmwares.find((f) => f.FirmwareId === firmwareId)?.Version;
    if (firmwareVersion !== undefined) {
      return firmwareVersion;
    }
    return '';
  };

  const confirmChangeFirmware = async (applyNow: boolean, forceValidation: boolean): Promise<void> => {
    const firmwareId = form.getFieldValue('ExpectedFirmwareVersion');
    const body = { ExpectedFirmwareId: firmwareId, ApplyNow: applyNow, ForceValidation: forceValidation };
    InfoNotification(t('saveConfigurationNotif'));
    closePopover();
    const response = await ChangeExpectedFirmware(props.stationId, body);

    if (response.isLeft()) {
      ErrorNotification(t('errorInstallingFirmware'));
    } else {
      SuccessNotification(t('firmwareInstallSuccess'));
      dispatch(
        EditConfigurationInfo({
          ...props.stationInfo,
          ExpectedFirmwareId: firmwareId,
          ExpectedFirmwareVersion: getFirmwareVersionById(firmwareId),
        })
      );
    }
  };

  return (
    <div>
      <h2>{t('firmware')}</h2>
      <Divider />
      <Form form={form}>
        <LabeledField
          label={t('currentFirmwareVersion')}
          value={props.stationInfo.CurrentFirmwareVersion}
          align="left"
        />
        <LabeledInputSelect
          name="ExpectedFirmwareVersion"
          label={t('expectedFirmwareVersion')}
          align="left"
          options={firmwares?.map((f) => {
            return { label: f.Version, value: f.FirmwareId };
          })}
        />
      </Form>
      <Button type="primary" onClick={changeVersionAction}>
        {t('changeVersion')}
      </Button>

      <Popover
        content={
          <div>
            <div className="drawer-close">
              <h2>{t('changeFirmware')}</h2>
              <CloseOutlined onClick={closePopover} />
            </div>
            <Divider />
            <div>
              <Space>
                <Button onClick={() => confirmChangeFirmware(true, false)} className="secondary-button">
                  {t('installNow')}
                </Button>
                <Button onClick={() => confirmChangeFirmware(true, true)} className="secondary-button">
                  {t('installNowAndValidate')}
                </Button>
                <Button onClick={() => confirmChangeFirmware(false, false)} className="secondary-button">
                  {t('installLater')}
                </Button>
              </Space>
            </div>
          </div>
        }
        trigger="click"
        visible={showChangeVersion}
      />
    </div>
  );
};
