import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { UsersReducer } from '../../users/users/redux/UsersReducer';
import { OwnersReducer } from '../../owners/redux/OwnersReducer';
import { ParksReducer } from '../../parks/redux/ParksReducer';
import { AppActions } from './ActionTypes';
import { RolesReducer } from '../../users/shared/redux/roles/RolesReducer';
import { CurrentUserReducer } from '../../users/shared/redux/currentuser/CurrentUserReducer';
import { ApiUsersReducer } from '../../users/api-users/redux/ApiUsersReducer';
import { ConfigurationReducer } from '../../stations/configuration/redux/ConfigurationReducer';

const rootReducer = combineReducers({
  users: UsersReducer,
  owners: OwnersReducer,
  roles: RolesReducer,
  parks: ParksReducer,
  currentUser: CurrentUserReducer,
  apiUsers: ApiUsersReducer,
  configuration: ConfigurationReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const RootStore = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>))
);
