import { ApiUser } from './models/ApiUser';
import HttpClient from '../../global/api/HttpClient';
import Configuration from '../../global/Configuration';

export const GetApiUsersRequest = async (): Promise<ApiUser[]> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/apiusers`);
    return response.data;
  } catch (ex) {
    return [];
  }
};

export const GetApiUserRequest = async (userId: string): Promise<ApiUser | undefined> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/apiusers/${userId}`);
    return response.data;
  } catch (ex) {
    return undefined;
  }
};

export const CreateApiUserRequest = async (apiUser: ApiUser): Promise<ApiUser | undefined> => {
  try {
    const response = await HttpClient.post(`${Configuration.CSNMS_BASE_URL}/api/security/apiusers`, apiUser);
    return response.data;
  } catch (ex) {
    return undefined;
  }
};

export const PatchApiUserRequest = async (userId: string, apiUser: ApiUser): Promise<ApiUser> => {
  const response = await HttpClient.patch(`${Configuration.CSNMS_BASE_URL}/api/security/apiusers/${userId}`, apiUser);
  return response.data;
};
