import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import React, { FocusEvent, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AmperageConfiguration } from '../../models/AmperageConfiguration';
import { SetAmperage } from '../../redux/ConfigurationActions';
import { LabeledInputText } from '../../../../shared/components/forms';
import { useValidateNumber } from '../../helpers/formValidation';

interface AmperageProps {
  amperageConfiguration: AmperageConfiguration;
  isEditAllowed: boolean;
}

export const AmperageData = (props: AmperageProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { MaximumCurrent } = props.amperageConfiguration;
  const { AmperageRange } = props.amperageConfiguration;
  let min = Number.MIN_VALUE;
  let max = Number.MAX_VALUE;

  if (AmperageRange && AmperageRange.match('[0-9]+-[0-9]+')) {
    const minMax = AmperageRange.split('-');
    min = parseFloat(minMax[0]);
    max = parseFloat(minMax[1]);
  }

  const validateNumber = useValidateNumber(min, max);

  useEffect(() => {
    form.setFieldsValue({
      MaximumCurrent,
    });
  }, [form, MaximumCurrent]);

  const updateForm = (e: FocusEvent<HTMLFormElement>): void => {
    const updatedAmperage = { ...props.amperageConfiguration, [e.target.id]: form.getFieldValue(e.target.id) };
    dispatch(SetAmperage(updatedAmperage));
  };

  return (
    <div>
      <Form colon={false} form={form} onBlur={updateForm}>
        <LabeledInputText
          label={t('amperageMaximum')}
          name="MaximumCurrent"
          validationRules={[{ validator: validateNumber }]}
          disabled={!props.isEditAllowed}
        />
      </Form>
    </div>
  );
};

export default AmperageData;
