import React, { FC } from 'react';
import { Form, Select } from 'antd';
import TooltipLabel from './ToolTipLabel';

export interface OptionProps {
  label: string;
  value: string | number;
}

interface LabeledInputSelectProps {
  label: string;
  name: string;
  options: Array<OptionProps>;
  align?: string;
}

const LabeledInputSelect: FC<LabeledInputSelectProps> = ({ label, name, options, align = 'right' }) => {
  return (
    <div className="field-group">
      <TooltipLabel label={label} name={name} align={align} />
      <Form.Item className="field-value" name={name}>
        <Select options={options} showSearch optionFilterProp="label" />
      </Form.Item>
    </div>
  );
};

export default LabeledInputSelect;
