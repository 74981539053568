import {
  ADD_USER,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  SET_OWNER_NAMES,
  UPDATE_FILTERED_LIST,
  UserActionTypes,
  USERS_LOADING,
  SET_EMAIL,
  SET_ROLES,
  SET_PARK_NAMES,
  SET_SHOW_INACTIVE,
  CLEAR_FILTERS,
} from './UserActionTypes';
import { UsersState } from './UsersState';
import { FilterUsers } from '../UserFilterHelper';

const initialState: UsersState = {
  users: [],
  filteredUsers: [],
  loading: false,
  fetchingFailed: false,
  ownerNamesFilter: [],
  emailFilter: '',
  roleFilter: [],
  parkNamesFilter: [],
  showInactiveUsers: false,
};

const UsersReducer = (state = initialState, action: UserActionTypes): UsersState => {
  switch (action.type) {
    case ADD_USER:
      return { ...state, users: state.users };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        fetchingFailed: false,
        loading: false,
      };
    case GET_USERS_FAIL:
      return { ...state, loading: false, users: [], fetchingFailed: true };
    case USERS_LOADING:
      return { ...state, loading: true };
    case SET_OWNER_NAMES:
      return {
        ...state,
        ownerNamesFilter: action.ownerNames,
      };
    case SET_EMAIL:
      return {
        ...state,
        emailFilter: action.email,
      };
    case SET_ROLES:
      return {
        ...state,
        roleFilter: action.roles,
      };
    case SET_PARK_NAMES:
      return {
        ...state,
        parkNamesFilter: action.parkNames,
      };
    case UPDATE_FILTERED_LIST:
      return {
        ...state,
        filteredUsers: FilterUsers(state),
      };
    case SET_SHOW_INACTIVE:
      return {
        ...state,
        showInactiveUsers: action.showInactiveUsers,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        emailFilter: '',
        parkNamesFilter: [],
        ownerNamesFilter: [],
        roleFilter: [],
        filteredUsers: state.users,
      };
    default:
      return state;
  }
};

export { UsersReducer };
