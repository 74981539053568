import React, { ReactElement } from 'react';
import { ConfigurationState } from '../../redux/ConfigurationState';
import { AdvancedConfigurationData } from './AdvancedConfigurationData';
import { useIsUserAdmin } from '../../helpers/UseIsAdmin';

interface AdvancedConfigurationProps {
  configurationState: ConfigurationState;
  stationId: string;
}

export const AdvancedConfiguration = (props: AdvancedConfigurationProps): ReactElement => {
  const isAdmin = useIsUserAdmin();
  if (
    props.configurationState.advancedComponents !== undefined &&
    props.configurationState.displayedCategories?.showAdvanced &&
    isAdmin
  ) {
    return (
      <AdvancedConfigurationData stationId={props.stationId} components={props.configurationState.advancedComponents} />
    );
  }
  return <div />;
};
