import React, { FC, ReactNode } from 'react';
import { Divider } from 'antd';

interface ConfigurationCategoryProps {
  id: string;
  CategoryData: ReactNode;
  categoryTitle: string;
}

const ConfigurationCategory: FC<ConfigurationCategoryProps> = (props) => {
  const { categoryTitle, CategoryData } = props;
  return (
    <div>
      <Divider />
      <div id={props.id} className="category-main-section">
        <div>
          <div className="category-title">{categoryTitle}</div>
        </div>
        <div className="category-edit-section">
          <div className="category-fields">
            <div>{CategoryData}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationCategory;
