import React, { ReactElement, useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { LeftCircleTwoTone } from '@ant-design/icons/lib';
import { ErrorNotification, InfoNotification, SuccessNotification } from '../../../../shared/helper/NotificationHelper';
import { GetConfigurationRequest } from '../../api/ConfigurationRequests';
import { GetStationConfiguration, SetAdvancedConfiguration } from '../../redux/ConfigurationActions';
import { AdvancedConfigurationComponent } from '../../models/AdvancedConfigurationComponent';

interface CompareStationValuesProps {
  stationId: string;
  compareModeEnabled: boolean;
  advancedComponents: AdvancedConfigurationComponent[];
  changeCompareMode: (value: boolean) => void;
}

export const CompareStationValues = (props: CompareStationValuesProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isCompareLoading, setIsCompareLoading] = useState(false);
  const [stationValuesLoaded, setStationValuesLoaded] = useState(false);

  const compareStationValues = async (): Promise<void> => {
    if (stationValuesLoaded) {
      props.changeCompareMode(true);
    } else {
      InfoNotification(t('saveConfigurationNotif'));
      setIsCompareLoading(true);
      const configuration = await GetConfigurationRequest(props.stationId, true);
      setIsCompareLoading(false);
      if (configuration.isRight()) {
        dispatch(GetStationConfiguration(configuration.value));
        setStationValuesLoaded(true);
        props.changeCompareMode(true);
      } else {
        ErrorNotification(t('compareValuesError'));
      }
    }
  };

  const applyStationValues = (): void => {
    const newAdvancedComponents = produce(props.advancedComponents, (draft) => {
      draft.forEach((c) => {
        c.Properties.forEach((p) => {
          // eslint-disable-next-line no-param-reassign
          p.PropertyValue = p.StationValue;
        });
      });
    });
    dispatch(SetAdvancedConfiguration(newAdvancedComponents));
    SuccessNotification(t('applyStationValuesSuccess'));
  };

  const closeCompareMode = (): void => {
    props.changeCompareMode(false);
  };

  return (
    <div className="compare-station-value-section">
      {props.compareModeEnabled && (
        <div style={{ display: 'flex' }}>
          <Button
            style={{ marginRight: '1rem' }}
            onClick={applyStationValues}
            className="secondary-button"
            size="large"
            type="primary"
          >
            <LeftCircleTwoTone /> {t('applyStationValues')}
          </Button>
          <Button onClick={closeCompareMode} className="secondary-button" size="large" type="primary">
            {t('closeCompareMode')}
          </Button>
        </div>
      )}
      {!props.compareModeEnabled && (
        <Button
          loading={isCompareLoading}
          onClick={compareStationValues}
          className="secondary-button"
          size="large"
          type="primary"
        >
          {t('compareStationBtn')}
        </Button>
      )}
    </div>
  );
};
