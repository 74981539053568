import { useTranslation } from 'react-i18next';
import { Breadcrumb, Button, Card, Divider, Form, Input } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import React, { FC } from 'react';
import { ErrorNotification, SuccessNotification } from '../../../../shared/helper/NotificationHelper';
import LargeOwnerSelector from '../../../shared/components/LargeOwnerSelector';
import LargeParkSelector from '../../../shared/components/LargeParkSelector';
import { ApiUser } from '../../models/ApiUser';
import { CreateApiUserRequest } from '../../ApiUserRequests';
import '../../../users/pages/users.scss';
import '../../../users/pages/create/createUser.scss';

const CreateApiUser: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onFinish = async (apiUser: ApiUser): Promise<void> => {
    const returnedUser = await CreateApiUserRequest(apiUser);

    if (returnedUser === undefined) {
      ErrorNotification(t('createApiUserError'));
    } else {
      SuccessNotification(t('createApiUserSuccess'));
      history.push('/apiusers');
    }
  };

  const onCancel = (): void => {
    history.push('/apiusers');
  };

  return (
    <div className="create-user-page">
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/apiusers">{t('manageApiUsers')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('newApi')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h2>{t('newApi')}</h2>
      </div>

      <Form onFinish={onFinish} layout="vertical">
        <Card className="create-user-card create-user-card-body" title={t('apiInformation')}>
          <div className="flex">
            <Form.Item
              name="DisplayName"
              className="medium-input-spaced"
              label={t('name')}
              rules={[{ required: true, message: t('requiredApiName') }]}
            >
              <Input placeholder={t('placeHolderApiName')} />
            </Form.Item>
            <Form.Item
              name="SubscriptionName"
              className="medium-input-spaced"
              label={t('subscriptionId')}
              rules={[{ required: true, message: t('requiredSubscriptionId') }]}
            >
              <Input placeholder={t('placeholderSubscriptionId')} />
            </Form.Item>
          </div>
          <Divider />
          <Form.Item name="Owners">
            <LargeOwnerSelector />
          </Form.Item>

          <div className="separator" />
          <Form.Item name="Parks">
            <LargeParkSelector />
          </Form.Item>
          <Form.Item>
            <div className="create-user-actions">
              <Button type="link" danger onClick={onCancel}>
                {t('cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {t('save')}
              </Button>
            </div>
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default CreateApiUser;
