import React, { ReactElement, useState } from 'react';
import { Menu } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConfigurationProfiles } from '../../../shared/enums/ConfigurationProfiles';
import { SetDisplayedCategories } from '../redux/ConfigurationActions';
import { ConfigurationState } from '../redux/ConfigurationState';
import { useIsUserAdmin } from '../helpers/UseIsAdmin';

interface ConfigurationProfileProps {
  configurationState: ConfigurationState;
}

export const ConfigurationProfileMenu = (props: ConfigurationProfileProps): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(ConfigurationProfiles.Simplified);
  const isAdmin = useIsUserAdmin();

  const onMenuClick: MenuClickEventHandler = (e): void => {
    const profiles: ConfigurationProfiles = ConfigurationProfiles[e.key as keyof typeof ConfigurationProfiles];
    setSelectedTab(profiles);
    dispatch(SetDisplayedCategories(profiles, props.configurationState.chargingStationInfo));
  };

  if (isAdmin) {
    return (
      <Menu onClick={onMenuClick} className="configuration-menu" mode="horizontal" selectedKeys={[selectedTab]}>
        <Menu.Item key={ConfigurationProfiles.Simplified} className="menu-item">
          {t('simplified')}
        </Menu.Item>
        <Menu.Item key={ConfigurationProfiles.Advanced} className="menu-item">
          {t('advanced')}
        </Menu.Item>
      </Menu>
    );
  }
  return <div />;
};
