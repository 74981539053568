import Axios from 'axios';
import Configuration from '../Configuration';

const HttpClient = Axios.create({
  baseURL: Configuration.CSNMS_BASE_URL,
  withCredentials: Configuration.WITH_CREDENTIALS,
  responseType: 'json',
});

HttpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      const currentUrl = window.location.href;
      window.location.href = `${Configuration.CSNMS_BASE_URL}/Default.aspx?ReturnUrl=${currentUrl}`;
    }
    return Promise.reject(error?.response);
  }
);

export default HttpClient;
