import React, { ReactElement, useState } from 'react';
import { Drawer, Form, Input } from 'antd';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import { GetDescriptionByLanguage } from '../../helpers/DescriptionLanguageHelper';
import { MultiValueStringDrawer } from './MultiValueStringDrawer';
import TooltipLabel from '../../../../shared/components/forms/ToolTipLabel';

export const MultiValueStringAdvancedProperty = (advancedProperty: AdvancedProperty): ReactElement => {
  const [visible, setVisible] = useState(false);

  const showDrawer = (): void => {
    setVisible(true);
  };
  const onClose = (): void => {
    setVisible(false);
  };

  return (
    <div role="button">
      <Drawer width="40rem" visible={visible} onClose={onClose} destroyOnClose>
        <MultiValueStringDrawer advancedProperty={advancedProperty} onClose={onClose} />
      </Drawer>
      <div className="field-group">
        <TooltipLabel
          label={GetDescriptionByLanguage(advancedProperty.DescriptionFr, advancedProperty.DescriptionEn)}
          name={advancedProperty.Name}
        />
        <Form.Item className="field-group" name={advancedProperty.Name}>
          <Input onClick={showDrawer} />
        </Form.Item>
      </div>
    </div>
  );
};
