import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../redux/store';
import { Roles } from '../../shared/enums/Roles';
import { GetCurrentUser, SetDefaultUser } from '../../users/shared/redux/currentuser/CurrentUserActions';
import Forbidden from '../pages/error/Forbidden';
import Loading from '../pages/loading/Loading';
import MainLayout from '../../shared/layout/MainLayout';
import Configuration from '../Configuration';

type routeParams = {
  render: (props: RouteComponentProps) => ReactNode;
  path: string;
  allowedRoles: Roles[];
};

const PrivateRoute: FC<routeParams> = (props) => {
  const currentUserState = useSelector((state: AppState) => state.currentUser);
  const dispatch = useDispatch();
  useEffect(() => {
    const loadCurrentUser = (): void => {
      if (!currentUserState.loaded && Configuration.DEACTIVATE_AUTH) {
        dispatch(SetDefaultUser([Roles.SUPER_ADMIN]));
      } else if (!currentUserState.loaded) {
        dispatch(GetCurrentUser());
      }
    };
    loadCurrentUser();
  }, [currentUserState.loaded, dispatch]);

  const isUserAllowed = (): boolean => {
    if (Configuration.DEACTIVATE_AUTH) {
      return true;
    }
    const rolesIntersection: Roles[] | undefined = currentUserState.user?.Roles?.filter((x) =>
      props.allowedRoles.includes(x)
    );
    const hasRole = rolesIntersection !== undefined && rolesIntersection.length > 0;
    return hasRole && currentUserState.loaded;
  };

  if (isUserAllowed()) {
    return <MainLayout path={props.path} render={props.render} />;
  }
  if (currentUserState.loaded) {
    return <Forbidden />;
  }
  return <Loading />;
};

export default PrivateRoute;
