import React, { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetRoles } from '../redux/roles/RolesActions';
import { AppState } from '../../../global/redux/store';

type RoleSelectorComponentProps = {
  onChange?: (roles: string[]) => void;
  initialRoles?: string[];
  showTitle?: boolean;
};

const RoleSelector: FC<RoleSelectorComponentProps> = (props) => {
  const roleState = useSelector((state: AppState) => state.roles);
  const { Option } = Select;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState(props.initialRoles || []);

  useEffect(() => {
    dispatch(GetRoles());
  }, [dispatch]);

  const onchange = (value: string[]): void => {
    if (props.onChange !== undefined) {
      props.onChange(value);
    }

    setRoles(value);
  };

  return (
    <div>
      {props.showTitle ? <div className="filter-name">{t('titleFilterRole')}</div> : ''}
      <Select
        mode="multiple"
        allowClear
        onChange={onchange}
        virtual={false}
        className="medium-input-spaced"
        placeholder={t('placeholderFilterRole')}
        value={roles}
      >
        {roleState.roles.map((role) => (
          <Option key={role} value={role} label={role}>
            <div>{role}</div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default RoleSelector;
