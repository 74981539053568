import { RolesState } from './RolesState';
import { GET_ROLES_FAIL, GET_ROLES_SUCCESS, RoleActionTypes } from './RoleActionTypes';

const initialState: RolesState = {
  roles: [],
  fetchingFailed: false,
};

const RolesReducer = (state = initialState, action: RoleActionTypes): RolesState => {
  switch (action.type) {
    case GET_ROLES_FAIL:
      return { roles: [], fetchingFailed: true };
    case GET_ROLES_SUCCESS:
      return { roles: action.roles, fetchingFailed: false };

    default:
      return state;
  }
};

export { RolesReducer };
