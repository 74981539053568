import React, { FC } from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import Configuration from '../../Configuration';

const Forbidden: FC = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="403"
      title="403"
      subTitle={t('forbiddenMessage')}
      extra={
        <Button type="primary" href={Configuration.CSNMS_BASE_URL}>
          {t('goToCSNMS')}
        </Button>
      }
    />
  );
};

export default Forbidden;
