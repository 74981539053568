import { notification } from 'antd';

export const ErrorNotification = (message: string, description = ''): void =>
  notification.error({
    message,
    description,
  });

export const SuccessNotification = (message: string, description = ''): void =>
  notification.success({
    message,
    description,
  });

export const InfoNotification = (message: string, description = ''): void =>
  notification.info({
    message,
    description,
  });
