import React, { FC } from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('NotFoundMessage')}
      extra={
        <Button type="primary" href="/users">
          {t('GoToUsers')}
        </Button>
      }
    />
  );
};

export default NotFound;
