import { User } from '../../../users/models/User';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const SET_DEFAULT_USER = 'SET_DEFAULT_USER';

export interface GetUserSuccess {
  type: typeof GET_USER_SUCCESS;
  user: User;
}

export interface GetUserFail {
  type: typeof GET_USER_FAIL;
}

export interface SetDefaultUser {
  type: typeof SET_DEFAULT_USER;
  user: User;
}

export type CurrentUserActionTypes = GetUserSuccess | GetUserFail | SetDefaultUser;
