import React, { FC, useEffect, FocusEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Rule } from 'antd/lib/form';
import { ChargingStationInfo } from '../../models/ChargingStationInfo';
import { EditConfigurationInfo } from '../../redux/ConfigurationActions';
import { LabeledInputText, LabeledSelect } from '../../../../shared/components/forms';
import { requiredWithMessage } from '../../helpers/formValidation';
import { OptionProps } from '../../../../shared/components/forms/LabeledSelect';
import { LabeledField } from '../../../../shared/components/forms/LabeledField';

interface GeolocationProps {
  stationInfo: ChargingStationInfo;
}

const GeolocationData: FC<GeolocationProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { Longitude, Latitude, Address, City, ProvinceOrState, Country, PostalOrZipcode, TimeZone } = props.stationInfo;

  useEffect(() => {
    form.setFieldsValue({
      Longitude,
      Latitude,
      Address,
      City,
      ProvinceOrState,
      Country,
      PostalOrZipcode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateForm = (e: FocusEvent<HTMLFormElement>): void => {
    const updatedStationInfos = { ...props.stationInfo, [e.target.id]: form.getFieldValue(e.target.id) };
    dispatch(EditConfigurationInfo(updatedStationInfos));
  };

  const getCountryOptions = (): OptionProps[] => [
    { label: 'United States', value: 'USA' },
    { label: 'Canada', value: 'canada' },
  ];

  const validateLatitude = (rule: Rule, value: number): Promise<void> => {
    if (Number.isNaN(value) || value > 90 || value < -90) {
      return Promise.reject(t('invalidLatitude'));
    }
    return Promise.resolve();
  };

  const validateLongitude = (rule: Rule, value: number): Promise<void> => {
    if (Number.isNaN(value) || value > 180 || value < -180) {
      return Promise.reject(t('invalidLongitude'));
    }
    return Promise.resolve();
  };

  return (
    <div>
      <Form colon={false} form={form} onBlur={updateForm}>
        <LabeledInputText
          label={t('longitude')}
          name="Longitude"
          validationRules={[requiredWithMessage(t('requiredField')), { validator: validateLongitude }]}
        />

        <LabeledInputText
          label={t('latitude')}
          name="Latitude"
          validationRules={[requiredWithMessage(t('requiredField')), { validator: validateLatitude }]}
        />

        <LabeledInputText
          label={t('address')}
          name="Address"
          validationRules={[requiredWithMessage(t('requiredField'))]}
        />

        <LabeledInputText label={t('city')} name="City" validationRules={[requiredWithMessage(t('requiredField'))]} />

        <LabeledInputText
          label={t('provinceOrState')}
          name="ProvinceOrState"
          validationRules={[requiredWithMessage(t('requiredField'))]}
        />

        <LabeledSelect name="Country" label={t('country')} options={getCountryOptions()} />

        <LabeledInputText
          label={t('postalOrZipcode')}
          name="PostalOrZipcode"
          validationRules={[requiredWithMessage(t('requiredField'))]}
        />

        <LabeledField label={t('timezone')} value={TimeZone} />
      </Form>
    </div>
  );
};

export default GeolocationData;
