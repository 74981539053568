import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationState } from '../../redux/ConfigurationState';
import ConfigurationCategory from '../ConfigurationCategory';
import { FirmwareData } from './FirmwareData';
import { useIsUserAdmin } from '../../helpers/UseIsAdmin';

interface FirmwareProps {
  configurationState: ConfigurationState;
  stationId: string;
}

export const Firmware = (props: FirmwareProps): ReactElement => {
  const { t } = useTranslation();
  const isAdmin = useIsUserAdmin();

  if (
    props.configurationState.displayedCategories?.showFirmware &&
    props.configurationState.chargingStationInfo !== undefined &&
    isAdmin
  ) {
    <ConfigurationCategory
      id="firmware"
      categoryTitle={t('firmware')}
      CategoryData={
        <FirmwareData stationInfo={props.configurationState.chargingStationInfo} stationId={props.stationId} />
      }
    />;
  }
  return <div />;
};
