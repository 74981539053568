import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import LabeledInputText from '../../../../shared/components/forms/LabeledInputText';
import { GetDescriptionByLanguage } from '../../helpers/DescriptionLanguageHelper';
import { requiredWithMessage } from '../../helpers/formValidation';

export const StringAdvancedProperty = (advancedProperty: AdvancedProperty): ReactElement => {
  const { t } = useTranslation();
  return (
    <LabeledInputText
      label={GetDescriptionByLanguage(advancedProperty.DescriptionFr, advancedProperty.DescriptionEn)}
      name={advancedProperty.Name}
      validationRules={[requiredWithMessage(t('requiredField'))]}
    />
  );
};
