export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export interface GetRolesSuccess {
  type: typeof GET_ROLES_SUCCESS;
  roles: string[];
}

export interface GetRolesFail {
  type: typeof GET_ROLES_FAIL;
}

export type RoleActionTypes = GetRolesSuccess | GetRolesFail;
