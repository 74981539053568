import React, { FC, ReactNode } from 'react';
import '../../global/i18n';
import { Layout, Avatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../global/redux/store';
import Configuration from '../../global/Configuration';

const { Header, Content } = Layout;
type routeParams = {
  render: (props: RouteComponentProps) => ReactNode;
  path: string;
};
const MainLayout: FC<routeParams> = (props) => {
  const currentUserState = useSelector((state: AppState) => state.currentUser);
  const { t } = useTranslation();
  return (
    <Layout>
      <Header className="layout-header">
        <div>
          <Space>
            <img src={Configuration.LOGO} height="35px" alt="" />
            <a href={Configuration.CSNMS_BASE_URL}>{t('goToCSNMS')}</a>
          </Space>
        </div>
        <div>
          <Space>
            <span>{currentUserState.user?.Email}</span>
            <Avatar className="avatar-background" icon={<UserOutlined />} />
          </Space>
        </div>
      </Header>
      <Content>
        <Route path={props.path} render={props.render} exact />;
      </Content>
    </Layout>
  );
};

export default MainLayout;
