import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Select, Breadcrumb, Divider, Modal } from 'antd';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RoleSelector from '../../../shared/components/RoleSelector';
import LargeOwnerSelector from '../../../shared/components/LargeOwnerSelector';
import LargeParkSelector from '../../../shared/components/LargeParkSelector';
import { User } from '../../models/User';
import { GetUserRequest, PatchUserRequest, DeactivateUserRequest, ReactivateUserRequest } from '../../UserRequests';
import { ErrorNotification, SuccessNotification } from '../../../../shared/helper/NotificationHelper';
import { Languages } from '../../../../shared/enums/Languages';
import Loading from '../../../../global/pages/loading/Loading';
import UserActivityLog from '../../../shared/components/UserActivityLog';
import '../users.scss';

const EditUser: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { Option } = Select;
  const userListUrl = '/users';
  const notFoundUrl = '/404';
  const [form] = Form.useForm();
  const [initialUser, setInitialUser] = useState(undefined as User | undefined);
  const { confirm } = Modal;

  const history = useHistory();

  useEffect(() => {
    async function getUser(): Promise<void> {
      const returnUser = await GetUserRequest(id);
      if (returnUser === undefined) {
        history.push(notFoundUrl);
      } else {
        setInitialUser(returnUser);
      }
    }
    getUser();
  }, [history, id, notFoundUrl]);

  const onFinish = async (values: User): Promise<void> => {
    const user = values;
    user.CSNMSUserId = initialUser?.CSNMSUserId;
    user.UserId = initialUser?.UserId || '';
    user.AccountEnabled = initialUser?.AccountEnabled || false;
    const result = await PatchUserRequest(user.UserId, user);
    if (result === undefined) {
      ErrorNotification(t('editUserSaveError'));
    } else {
      SuccessNotification(t('editUserSuccess'));
      history.push(userListUrl);
    }
  };
  const userActivationConfirmation = (): void => {
    confirm({
      title: t('confirmation'),
      content: initialUser?.AccountEnabled === true ? t('deactivateText') : t('reactivateText'),
      onOk() {
        return initialUser?.AccountEnabled === true ? deactivateUser() : reactivateUser();
      },
      onCancel() {
        return null;
      },
    });
  };
  const deactivateUser = async (): Promise<void> => {
    const result = await DeactivateUserRequest(initialUser?.UserId || '');
    if (result === undefined || result !== 200) {
      ErrorNotification(t('deactivateError'));
    } else {
      SuccessNotification(t('deactivateSuccess'));
      history.push(userListUrl);
    }
  };

  const reactivateUser = async (): Promise<void> => {
    const result = await ReactivateUserRequest(initialUser?.UserId || '');
    if (result === undefined || result !== 200) {
      ErrorNotification(t('reactivateError'));
    } else {
      SuccessNotification(t('reactivateSuccess'));
      history.push(userListUrl);
    }
  };
  return initialUser === undefined ? (
    <Loading />
  ) : (
    <div className="user-list">
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={userListUrl}>{t('manageUsers')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('editUserInformation')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h2>{initialUser?.Email}</h2>
      </div>
      <Form form={form} className="full-width" initialValues={initialUser} onFinish={onFinish} layout="vertical">
        <div className="section">
          <h3>{t('editUserTitle')}</h3>
          <Divider />
          <div className="filters">
            <Form.Item
              label={t('Email')}
              name="Email"
              className="medium-input-spaced"
              rules={[
                {
                  required: true,
                  message: t('ErrorMissingEmail'),
                },
                {
                  type: 'email',
                  message: t('errorInvalidFormatEmail'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="medium-input-spaced"
              name="Language"
              label={t('titleLanguage')}
              rules={[
                {
                  required: true,
                  message: t('errorMissingLanguage'),
                },
              ]}
            >
              <Select placeholder={t('placeholderLanguage')}>
                <Option value={Languages.FRENCH}>Français</Option>
                <Option value={Languages.ENGLISH}>English</Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="medium-input-spaced"
              label={t('titleFilterRole')}
              name="Roles"
              rules={[
                {
                  required: true,
                  message: t('errorMissingRole'),
                },
              ]}
            >
              <RoleSelector initialRoles={initialUser?.Roles} />
            </Form.Item>
            <Form.Item label={t('CsnmsUserId')} name="CSNMSUserId" className="medium-input-spaced">
              <Input disabled />
            </Form.Item>
          </div>
          <Divider />
          <Form.Item name="Owners">
            <LargeOwnerSelector initialOwners={initialUser?.Owners} />
          </Form.Item>
          <Form.Item name="Parks">
            <LargeParkSelector initialParks={initialUser?.Parks} />
          </Form.Item>
        </div>
        <UserActivityLog UserId={initialUser?.UserId} />
        <Button type="primary" danger className="edit-user-form-action" onClick={userActivationConfirmation}>
          {initialUser?.AccountEnabled ? t('deactivate') : t('reactivate')}
        </Button>
        <div className="footer">
          <Form.Item>
            <Button type="link" danger>
              <Link to={userListUrl}>{t('cancel')}</Link>
            </Button>
            <Button type="primary" className="edit-user-form-action-submit" htmlType="submit">
              {t('save')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EditUser;
