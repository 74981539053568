import React, { FC } from 'react';
import { Form, Switch } from 'antd';
import TooltipLabel from './ToolTipLabel';

interface LabeledSwitchProps {
  label: string;
  name: string;
}

const LabeledSwitch: FC<LabeledSwitchProps> = ({ label, name }) => {
  return (
    <div className="field-group">
      <TooltipLabel label={label} name={name} align="right" />
      <Form.Item className="field-value" name={name} valuePropName="checked">
        <Switch />
      </Form.Item>
    </div>
  );
};

export default LabeledSwitch;
