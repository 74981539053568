import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '../shared/resources/resources.json';

i18n.use(initReactI18next).init({
  resources,
  lng: getDefaultLanguage(),

  keySeparator: false, // enable to use keys in the form messages.welcome

  interpolation: {
    escapeValue: false, // react already safe from xss
  },
});

function isSupportedLanguage(language: string): boolean {
  const languageWithoutLocale = language.slice(0, 2).toLowerCase();
  if (languageWithoutLocale === 'fr' || languageWithoutLocale === 'en') return true;
  return false;
}

export function getDefaultLanguage(): string {
  if (isSupportedLanguage(navigator.language)) {
    return navigator.language;
  }
  return 'en';
}

export default i18n;
