import { ApiUser } from './models/ApiUser';
import { ApiUsersState } from './redux/ApiUsersState';

const FilterOwnerNames = (apiUsers: ApiUser[], ownerNames?: string[]): ApiUser[] => {
  if (ownerNames == null || ownerNames.length === 0) {
    return apiUsers;
  }
  return apiUsers.filter((u) => u.Owners.filter((value) => ownerNames?.includes(value.Description)).length > 0);
};

const FilterName = (apiUsers: ApiUser[], name: string): ApiUser[] => {
  if (name === null || name === undefined) {
    return apiUsers;
  }
  return apiUsers.filter((u) => u.DisplayName.includes(name));
};

const FilterParkNames = (apiUsers: ApiUser[], parkNames?: string[]): ApiUser[] => {
  if (parkNames == null || parkNames.length === 0) {
    return apiUsers;
  }
  return apiUsers.filter((u) => u.Parks.filter((value) => parkNames?.includes(value.Description)).length > 0);
};

export function FilterApiUsers(state: ApiUsersState): ApiUser[] {
  let apiUsers = FilterOwnerNames(state.apiUsers, state.ownerNamesFilter);
  apiUsers = FilterParkNames(apiUsers, state.parkNamesFilter);
  apiUsers = FilterName(apiUsers, state.nameFilter);
  return apiUsers;
}
