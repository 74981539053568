import React, { ReactElement } from 'react';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import { LabeledSwitch } from '../../../../shared/components/forms';
import { GetDescriptionByLanguage } from '../../helpers/DescriptionLanguageHelper';

export const BooleanAdvancedProperty = (advancedProperty: AdvancedProperty): ReactElement => {
  return (
    <LabeledSwitch
      label={GetDescriptionByLanguage(advancedProperty.DescriptionFr, advancedProperty.DescriptionEn)}
      name={advancedProperty.Name}
    />
  );
};
