import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationState } from '../../redux/ConfigurationState';
import ConfigurationCategory from '../ConfigurationCategory';
import GeolocationData from './GeolocationData';
import { useIsUserAdmin } from '../../helpers/UseIsAdmin';

interface GeolocationProps {
  configurationState: ConfigurationState;
}

const Geolocation = (props: GeolocationProps): ReactElement => {
  const { t } = useTranslation();
  const isAdmin = useIsUserAdmin();

  if (
    props.configurationState.chargingStationInfo !== undefined &&
    props.configurationState.displayedCategories?.showGeolocation &&
    isAdmin
  ) {
    return (
      <ConfigurationCategory
        id="geolocation"
        categoryTitle={t('geolocation')}
        CategoryData={<GeolocationData stationInfo={props.configurationState.chargingStationInfo} />}
      />
    );
  }

  return <div />;
};

export default Geolocation;
