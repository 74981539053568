import React, { ReactElement } from 'react';
import { Anchor } from 'antd';
import './ConfigurationContentSideMenu.scss';
import { useTranslation } from 'react-i18next';
import { ConfigurationState } from '../redux/ConfigurationState';
import { useIsUserAdmin } from '../helpers/UseIsAdmin';

interface SideMenuProps {
  configurationState: ConfigurationState;
}

const { Link } = Anchor;

const ConfigurationContentSideMenu = (props: SideMenuProps): ReactElement => {
  const { t } = useTranslation();
  const isAdmin = useIsUserAdmin();

  if (props.configurationState.displayedCategories === undefined) {
    return <></>;
  }

  const { displayedCategories, advancedComponents } = props.configurationState;
  if (isAdmin) {
    return (
      <Anchor bounds={300} className="category-anchor">
        {displayedCategories?.showIdentification && <Link href="#identification" title={t('identification')} />}
        {displayedCategories?.showGeolocation && <Link href="#geolocation" title={t('geolocation')} />}
        {displayedCategories?.showFirmware && <Link href="#firmware" title={t('firmware')} />}
        {displayedCategories?.showPricing && <Link href="#pricing" title={t('pricing')} />}
        {displayedCategories?.showAdvanced &&
          advancedComponents.map((c) => {
            const componentRef = `#${c.Name}`;
            return (
              <div key={c.Name}>
                <Link href={componentRef} title={c.DescriptionEn} />
              </div>
            );
          })}
      </Anchor>
    );
  }

  return <div />;
};

export default ConfigurationContentSideMenu;
