import React, { FC, FocusEvent, ReactElement, useState } from 'react';
import { Button, Card, Divider, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Guid } from 'guid-typescript';
import { CloseOutlined } from '@ant-design/icons/lib';
import { useDispatch } from 'react-redux';
import produce from 'immer';
import { GetDescriptionByLanguage } from '../../helpers/DescriptionLanguageHelper';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import { SetAdvancedProperty } from '../../redux/ConfigurationActions';

interface MultiValueStringDrawerProps {
  advancedProperty: AdvancedProperty;
  onClose: () => void;
}

export const MultiValueStringDrawer: FC<MultiValueStringDrawerProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [arrayValues, setArrayValues] = useState(
    props.advancedProperty.PropertyValue.split(';').map((x) => {
      return { value: x, id: Guid.create() };
    })
  );

  const editStringValue = (e: FocusEvent<HTMLInputElement>, id: Guid): void => {
    const index = arrayValues.findIndex((x) => x.id === id);
    setArrayValues(
      arrayValues
        .slice(0, index)
        .concat({ value: e.target.value, id })
        .concat(arrayValues.slice(index + 1))
    );
  };

  const addStringValue = (): void => {
    setArrayValues(arrayValues.concat({ value: '', id: Guid.create() }));
  };

  const removeStringValue = (id: Guid): void => {
    const index = arrayValues.findIndex((x) => x.id === id);
    setArrayValues(arrayValues.slice(0, index).concat(arrayValues.slice(index + 1)));
  };

  const saveStringValues = (): void => {
    const newPropertyValue = arrayValues.map((x) => x.value).join(';');
    const newAdvancedProperty = produce(props.advancedProperty, (draft) => {
      draft.PropertyValue = newPropertyValue;
    });
    dispatch(SetAdvancedProperty(newAdvancedProperty));
    props.onClose();
  };

  return (
    <div>
      <h2>{GetDescriptionByLanguage(props.advancedProperty.DescriptionFr, props.advancedProperty.DescriptionEn)}</h2>
      <Divider />
      <div className="drawer-string-values">
        <Card
          title="Values"
          extra={
            <Button className="secondary-button" onClick={addStringValue}>
              Add
            </Button>
          }
        >
          {arrayValues.map((x) => {
            return (
              <div key={x.id.toString()} className="drawer-value-row">
                <Input className="drawer-value-input" onBlur={(e) => editStringValue(e, x.id)} defaultValue={x.value} />
                <CloseOutlined onClick={() => removeStringValue(x.id)} />
              </div>
            );
          })}
        </Card>
      </div>
      <div className="drawer-actions">
        <Button className="drawer-cancel" danger type="text" onClick={props.onClose}>
          {t('cancel')}
        </Button>
        <Button type="primary" onClick={saveStringValues}>
          {t('save')}
        </Button>
      </div>
    </div>
  );
};
