import React, { ReactElement } from 'react';
import { GetDescriptionByLanguage } from '../../helpers/DescriptionLanguageHelper';
import { LabeledInputText } from '../../../../shared/components/forms';
import { AdvancedProperty } from '../../models/AdvancedProperty';
import { useValidateNumber } from '../../helpers/formValidation';

interface NumberAdvancedPropertyProps {
  advancedProperty: AdvancedProperty;
}

export const NumberAdvancedProperty = (props: NumberAdvancedPropertyProps): ReactElement => {
  let min = Number.MIN_VALUE;
  let max = Number.MAX_VALUE;

  if (props.advancedProperty.Range.match('[0-9]+-[0-9]+')) {
    const minMax = props.advancedProperty.Range.split('-');
    min = parseFloat(minMax[0]);
    max = parseFloat(minMax[1]);
  }

  const validateNumber = useValidateNumber(min, max);

  return (
    <LabeledInputText
      label={GetDescriptionByLanguage(props.advancedProperty.DescriptionFr, props.advancedProperty.DescriptionEn)}
      validationRules={[{ validator: validateNumber }]}
      name={props.advancedProperty.Name}
    />
  );
};
