import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { AdvancedConfigurationComponent } from '../../models/AdvancedConfigurationComponent';
import ConfigurationCategory from '../ConfigurationCategory';
import { GetDescriptionByLanguage } from '../../helpers/DescriptionLanguageHelper';
import { SetAdvancedConfiguration } from '../../redux/ConfigurationActions';
import { AdvancedCategory } from './AdvancedCategory';

import { CompareStationValues } from './CompareStationValues';

interface AdvancedConfigurationProps {
  stationId: string;
  components: AdvancedConfigurationComponent[];
}

export const AdvancedConfigurationData: FC<AdvancedConfigurationProps> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [compareModeEnabled, setCompareModeEnabled] = useState(false);

  const changeCompareMode = (value: boolean): void => {
    setCompareModeEnabled(value);
  };

  useEffect(() => {
    const fieldData: { name: string; value: string }[] = [];
    const advancedProperties = props.components.flatMap((c) => c.Properties);
    advancedProperties.forEach((p) => {
      // special case for boolean because switch component needs a bool type to work
      if (p.JavascriptType === 'Boolean') {
        fieldData.push({
          name: p.Name,
          value: JSON.parse(p.PropertyValue),
        });
      } else {
        fieldData.push({
          name: p.Name,
          value: p.PropertyValue,
        });
      }
    });

    form.setFields(fieldData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const updateForm = (e: FocusEvent<HTMLFormElement>): void => {
    const name = e.target.id;
    const propertyValue = form.getFieldValue(e.target.id);

    const modifiedAdvancedComponents: AdvancedConfigurationComponent[] = produce(props.components, (draft) => {
      const componentIndex = props.components.findIndex((c) => c.Properties.find((p) => p.Name === name));

      if (componentIndex !== -1) {
        const propertyIndex = draft[componentIndex].Properties.findIndex((x) => x.Name === name);
        draft[componentIndex].Properties[propertyIndex].PropertyValue = String(propertyValue);
      }
    });

    dispatch(SetAdvancedConfiguration(modifiedAdvancedComponents));
  };

  return (
    <div className="configuration-section">
      <h2 className="configuration-title">{t('advancedConfiguration')}</h2>
      <CompareStationValues
        advancedComponents={props.components}
        compareModeEnabled={compareModeEnabled}
        stationId={props.stationId}
        changeCompareMode={changeCompareMode}
      />
      <Form form={form} onBlur={updateForm}>
        {props.components.map((c) => {
          return (
            <div key={c.Name}>
              <ConfigurationCategory
                id={c.Name}
                categoryTitle={GetDescriptionByLanguage(c.DescriptionFr, c.DescriptionEn)}
                CategoryData={<AdvancedCategory compareModeEnabled={compareModeEnabled} properties={c.Properties} />}
              />
            </div>
          );
        })}
      </Form>
    </div>
  );
};
