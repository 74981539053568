import { CurrentUserState } from './CurrentUserState';
import { CurrentUserActionTypes, GET_USER_FAIL, GET_USER_SUCCESS, SET_DEFAULT_USER } from './CurrentUserActionTypes';

const initialState: CurrentUserState = {
  user: undefined,
  fetchingFailed: false,
  loaded: false,
};

const CurrentUserReducer = (state = initialState, action: CurrentUserActionTypes): CurrentUserState => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return { fetchingFailed: false, loaded: true, user: action.user };
    case GET_USER_FAIL:
      return { ...state, fetchingFailed: true, loaded: true };
    case SET_DEFAULT_USER:
      return { ...state, user: action.user, loaded: true };
    default:
      return state;
  }
};

export { CurrentUserReducer };
