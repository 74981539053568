import React, { ChangeEvent, FC, useState } from 'react';
import { Button, Divider, Form, InputNumber, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import { Rule } from 'antd/lib/form';
import { ChargingStationPricing } from '../../models/pricing/ChargingStationPricing';
import { PricingMode } from '../../../../shared/enums/PricingMode';
import { SET_PRICING } from '../../redux/ConfigurationActionTypes';
import TooltipLabel from '../../../../shared/components/forms/ToolTipLabel';
import { ErrorNotification, InfoNotification, SuccessNotification } from '../../../../shared/helper/NotificationHelper';
import { ApplyPricingToStationRequest } from '../../api/ConfigurationRequests';
import { AppState } from '../../../../global/redux/store';

interface PricingDrawerProps {
  stationPricing: ChargingStationPricing;
  stationId: string;
  onClose: () => void;
}

export const PricingDrawer: FC<PricingDrawerProps> = (props) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const [configurationActionLoading, setConfigurationActionLoading] = useState(false);
  const configurationState = useSelector((state: AppState) => state.configuration);

  const HourInSeconds = 3600;
  const precision = 5;
  const perSessionPrecision = 2;

  const RequiredValidation = {
    required: true,
    message: t('requiredError'),
  };

  const DurationOptions = [
    { display: '15m', value: 0.25 },
    { display: '30m', value: 0.5 },
    { display: '45m', value: 0.75 },
    { display: '1h', value: 1 },
    { display: '2h', value: 2 },
    { display: '3h', value: 3 },
    { display: '4h', value: 4 },
    { display: '6h', value: 6 },
    { display: '8h', value: 8 },
    { display: '12h', value: 12 },
    { display: '16h', value: 16 },
    { display: '24h', value: 24 },
  ];

  const setInitialPricingMode = (): PricingMode => {
    if (props.stationPricing.IsTwoPeriodsTariff) {
      return PricingMode.TwoPeriod;
    }
    return props.stationPricing.PricingMode;
  };

  const setInitialPrice1 = (): number => {
    if (props.stationPricing.IsTwoPeriodsTariff) {
      return props.stationPricing.TwoPeriodTariff?.FirstPeriodPrice || 0;
    }
    if (pricingMode === PricingMode.Complex) {
      return 0;
    }
    return props.stationPricing.SimpleTariff?.Cost || 0;
  };

  const setInitialPrice2 = (): number => {
    if (props.stationPricing.IsTwoPeriodsTariff) {
      return props.stationPricing.TwoPeriodTariff?.SecondPeriodPrice || 0;
    }
    return 0;
  };

  const setInitialDuration = (): number => {
    if (props.stationPricing.IsTwoPeriodsTariff && props.stationPricing.TwoPeriodTariff) {
      return props.stationPricing.TwoPeriodTariff.FirstPeriodDuration / HourInSeconds;
    }
    return 1;
  };

  const [pricingMode, setPricingMode] = useState(setInitialPricingMode());
  const [price1, setPrice1] = useState(setInitialPrice1());
  const [price2, setPrice2] = useState(setInitialPrice2());
  const [duration, setDuration] = useState(setInitialDuration());
  const [tariffJson, setTariffJson] = useState(JSON.stringify(props.stationPricing.Tariff));

  const onChangePricingMode = (value: number): void => {
    setPricingMode(value);
  };

  const onChangeDuration = (value: number): void => {
    setDuration(value);
  };

  const onChangePrice1 = (value: number | null): void => {
    if (value == null) setPrice1(0);
    else setPrice1(value);
  };

  const onChangePrice2 = (value: number | null): void => {
    if (value == null) setPrice2(0);
    else setPrice2(value);
  };

  const onChangeComplexTariff = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setTariffJson(event.target.value);
  };

  const validateTariffJson = (rule: Rule, value: string): Promise<void> => {
    try {
      if (value === '') {
        return Promise.resolve();
      }
      JSON.parse(value);
      return Promise.resolve();
    } catch (Exception) {
      return Promise.reject(t('invalidTariff'));
    }
  };

  const onFinish = async (): Promise<void> => {
    let pricing = props.stationPricing;
    if (pricingMode !== PricingMode.Complex && pricingMode !== PricingMode.TwoPeriod) {
      pricing = setSimplePricing();
    } else if (pricingMode === PricingMode.TwoPeriod) {
      pricing = setTwoPeriodPricing();
    } else {
      pricing = setComplexPricing();
    }
    dispatch({ type: SET_PRICING, pricing });
    await ApplyPricingToStation();
  };

  const setSimplePricing = (): ChargingStationPricing => {
    const pricing = props.stationPricing;
    pricing.PricingMode = pricingMode;
    pricing.SimpleTariff = {
      Cost: price1,
    };
    pricing.IsTwoPeriodsTariff = false;
    return pricing;
  };

  const setTwoPeriodPricing = (): ChargingStationPricing => {
    const pricing = props.stationPricing;
    pricing.PricingMode = PricingMode.Complex;
    pricing.IsTwoPeriodsTariff = true;
    pricing.TwoPeriodTariff = {
      FirstPeriodPrice: price1,
      FirstPeriodDuration: duration * HourInSeconds,
      SecondPeriodPrice: price2,
    };
    return pricing;
  };

  const setComplexPricing = (): ChargingStationPricing => {
    const pricing = props.stationPricing;
    pricing.PricingMode = PricingMode.Complex;
    pricing.Tariff = JSON.parse(tariffJson);
    pricing.IsTwoPeriodsTariff = false;
    return pricing;
  };

  const ApplyPricingToStation = async (): Promise<void> => {
    confirm({
      title: t('confirmation'),
      content: t('applyConfigurationWarning'),
      onOk() {
        ApplyPricingAction();
        return null;
      },
      onCancel() {
        return null;
      },
    });

    const ApplyPricingAction = async (): Promise<void> => {
      if (configurationState.chargingStationPricing !== undefined) {
        InfoNotification(t('saveConfigurationNotif'));
        setConfigurationActionLoading(true);
        const result = await ApplyPricingToStationRequest(configurationState.chargingStationPricing, props.stationId);
        setConfigurationActionLoading(false);
        if (result.isRight()) {
          SuccessNotification(t('applyConfigurationSuccess'));
        } else {
          ErrorNotification(t('applyConfigurationError'));
        }
      }
    };
  };

  return (
    <div>
      <div>
        <h2>{t('configurePricing')}</h2>
        <Divider />
        <Form className="pricing-drawer-content" onFinish={onFinish}>
          <div style={{ display: 'flex' }}>
            <TooltipLabel label={t('pricingMode')} name="PricingMode" />
            <Form.Item className="field-group" name="PricingMode">
              <div>
                <Select value={pricingMode} onChange={onChangePricingMode}>
                  <Option value={PricingMode.Session} key={PricingMode.Session}>
                    {t('sessionPricing')}
                  </Option>
                  <Option value={PricingMode.Hourly} key={PricingMode.Hourly}>
                    {t('hourlyPricing')}
                  </Option>
                  {props.stationPricing.IsPerChargingHourTariffModeSupported && (
                    <Option value={PricingMode.PerChargingHour} key={PricingMode.PerChargingHour}>
                      {t('perChargingHourPricing')}
                    </Option>
                  )}
                  <Option value={PricingMode.EnergyPerKwh} key={PricingMode.EnergyPerKwh}>
                    {t('energyPricing')}
                  </Option>
                  {props.stationPricing.IsComplexTariffConfigured && (
                    <Option value={PricingMode.TwoPeriod} key={PricingMode.TwoPeriod}>
                      {t('twoPeriodPricing')}
                    </Option>
                  )}
                  {props.stationPricing.IsComplexTariffConfigured && (
                    <Option value={PricingMode.Complex} key={PricingMode.Complex}>
                      {t('complexPricing')}
                    </Option>
                  )}
                </Select>
              </div>
            </Form.Item>
          </div>
          {pricingMode !== PricingMode.Complex && pricingMode !== PricingMode.TwoPeriod && (
            <div style={{ display: 'flex' }}>
              <div className="field-label">{t('cost')}</div>
              <Form.Item className="field-group" name="Cost" rules={[RequiredValidation]}>
                <div>
                  <InputNumber
                    className="input-number-field"
                    value={price1}
                    precision={pricingMode === PricingMode.Session ? perSessionPrecision : precision}
                    onChange={(value) => onChangePrice1(value)}
                  />
                </div>
              </Form.Item>
            </div>
          )}

          {pricingMode === PricingMode.TwoPeriod && (
            <div>
              <Divider />
              <div>
                <h3>{t('firstPeriod')}</h3>
                <div style={{ display: 'flex' }}>
                  <div className="field-label">{t('ratePerHour')}</div>
                  <Form.Item className="field-group" name="Price1" rules={[RequiredValidation]}>
                    <div>
                      <InputNumber
                        className="input-number-field"
                        value={price1}
                        precision={precision}
                        onChange={(value) => onChangePrice1(value)}
                      />
                    </div>
                  </Form.Item>
                </div>
                <div style={{ display: 'flex' }}>
                  <div className="field-label">{t('duration')}</div>
                  <Form.Item className="field-group" name="Duration" rules={[RequiredValidation]}>
                    <div>
                      <Select showSearch value={duration} onChange={onChangeDuration} placeholder={t('selectDuration')}>
                        {DurationOptions.map((duration) => (
                          <Option key={duration.value} value={duration.value} label={duration.display}>
                            <div>{duration.display}</div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </div>
              </div>
              <Divider />
              <div>
                <h3>{t('secondPeriod')}</h3>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div className="field-label">{t('ratePerHour')}</div>
                    <Form.Item className="field-group" name="Price2" rules={[RequiredValidation]}>
                      <div>
                        <InputNumber
                          className="input-number-field"
                          value={price2}
                          precision={precision}
                          onChange={(value) => onChangePrice2(value)}
                        />
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          )}

          {pricingMode === PricingMode.Complex && (
            <div>
              <div style={{ display: 'flex' }}>
                <div className="field-label">{t('cost')}</div>
                <Form.Item
                  className="field-group"
                  name="Tariff"
                  rules={[
                    RequiredValidation,
                    {
                      validator: validateTariffJson,
                    },
                  ]}
                >
                  <div>
                    <TextArea value={tariffJson} rows={12} onChange={onChangeComplexTariff} />
                  </div>
                </Form.Item>
              </div>
            </div>
          )}
          <div className="drawer-actions">
            <Button
              className="drawer-cancel"
              danger
              type="text"
              onClick={props.onClose}
              loading={configurationActionLoading}
            >
              {t('close')}
            </Button>
            <Button type="primary" onClick={onFinish} loading={configurationActionLoading}>
              {t('applyChangesBtn')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
