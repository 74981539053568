import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplyFactoryReset } from '../api/ConfigurationRequests';
import { ErrorNotification, SuccessNotification } from '../../../shared/helper/NotificationHelper';
import { useIsUserAdmin } from '../helpers/UseIsAdmin';

interface FactoryResetProps {
  stationId: string;
}

export const FactoryReset: FC<FactoryResetProps> = ({ stationId }) => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const isAdmin = useIsUserAdmin();

  const submitFactoryReset = (): void => {
    const factoryResetAction = async (): Promise<void> => {
      const result = await ApplyFactoryReset(stationId);
      if (result.isLeft()) {
        ErrorNotification(t('factoryResetError'));
      } else {
        SuccessNotification(t('factoryResetSuccess'));
      }
    };

    confirm({
      title: t('confirmation'),
      content: t('factoryResetWarning'),
      onOk() {
        return factoryResetAction();
      },
      onCancel() {
        return null;
      },
    });
  };

  if (isAdmin) {
    return (
      <div className="factory-reset-section">
        <div className="FactoryResetText">
          <h3>{t('factoryResetTitle')}</h3>
          <div>{t('factoryResetText')}</div>
        </div>
        <Button className="factory-reset-button" size="large" type="primary" danger onClick={submitFactoryReset}>
          {t('factoryResetBtn')}
        </Button>
      </div>
    );
  }
  return <div />;
};
