import { Rule } from 'antd/es/form';
import { useTranslation } from 'react-i18next';

export const requiredWithMessage = (message: string): { required: boolean; message: string } => ({
  required: true,
  message,
});

export const useValidateNumber = (
  min: number = Number.MIN_VALUE,
  max: number = Number.MAX_VALUE
): ((rule: Rule, value: string) => Promise<void>) => {
  const { t } = useTranslation();

  const validateNumber = (rule: Rule, value: string): Promise<void> => {
    if (!value) return Promise.reject(t('requiredField'));

    const numValue = Number.parseFloat(value);

    if (Number.isNaN(numValue)) return Promise.reject(t('invalidValue'));

    if (numValue < min || numValue > max)
      return Promise.reject(t('invalidValueWithRange', { minimum: min, maximum: max }));

    return Promise.resolve();
  };

  return validateNumber;
};
