import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { Skeleton, Space, Spin } from 'antd';
import { CarOutlined } from '@ant-design/icons/lib';
import './loading.scss';

const Loading: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="loading-page">
      <Skeleton loading active />
      <div className="loading-text">{t('loadingText')}</div>
      <Space size="middle">
        <CarOutlined />
        <Spin />
      </Space>
    </div>
  );
};

export default Loading;
