import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ExpandableItemListComponentProps = {
  items: string[];
};

const Items: FC<ExpandableItemListComponentProps> = (props) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(3);

  const showAllItems = (value: number): void => {
    setLimit(value);
  };

  return (
    <div>
      {props.items.slice(0, limit).map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="list-column-element" key={index}>
          {item}
        </div>
      ))}
      {props.items.length - limit > 0 ? (
        <div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <div className="display-column-element-limit" onClick={() => showAllItems(props.items.length)}>
            {t('suffixMoreItems')} {props.items.length - limit} {t('prefixMoreItems')}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Items;
