import React, { ReactElement, useState } from 'react';
import { Button, Card, Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import produce from 'immer';
import { DisplayConfiguration } from '../../../../models/displayConfiguration/DisplayConfiguration';
import { DisplayConfigurationMessage } from '../../../../models/displayConfiguration/DisplayConfigurationMessage';
import { DisplayConfigurationLanguage } from './DisplayConfigurationLanguage';
import { SetDisplayConfiguration } from '../../../../redux/ConfigurationActions';
import { ErrorNotification, SuccessNotification } from '../../../../../../shared/helper/NotificationHelper';
import { ApplyDisplayConfigurationRequest } from '../../../../api/ConfigurationRequests';

interface DisplayConfigurationDrawerProps {
  displayConfiguration: DisplayConfiguration;
  stationId: string;
  onClose: () => void;
}

export const DisplayConfigurationDrawer = (props: DisplayConfigurationDrawerProps): ReactElement => {
  const { t } = useTranslation();
  const [displayConfiguration, setDisplayConfiguration] = useState(props.displayConfiguration);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { confirm } = Modal;

  const OFFSET = 2; // offset for pricing and welcome messages

  const changeMessage = (newMessage: DisplayConfigurationMessage, index: number, isFirstLanguage: boolean): void => {
    const newDisplayConfiguration = produce(displayConfiguration, (draft) => {
      if (isFirstLanguage) {
        draft.Lang1[index + OFFSET] = newMessage;
      } else {
        draft.Lang2[index + OFFSET] = newMessage;
      }
    });
    setDisplayConfiguration(newDisplayConfiguration);
  };

  const removeMessage = (index: number, isFirstLanguage: boolean): void => {
    const newDisplayConfiguration = produce(displayConfiguration, (draft) => {
      if (isFirstLanguage) {
        draft.Lang1.splice(index + OFFSET, 1);
      } else {
        draft.Lang2.splice(index + OFFSET, 1);
      }
    });
    setDisplayConfiguration(newDisplayConfiguration);
  };

  const ApplyDisplayConfiguration = async (): Promise<void> => {
    setIsLoading(true);
    const result = await ApplyDisplayConfigurationRequest(displayConfiguration, props.stationId);
    setIsLoading(false);
    if (result.isRight()) {
      SuccessNotification(t('applyConfigurationSuccess'));
    } else {
      ErrorNotification(t('applyConfigurationError'));
    }
  };

  const ApplyDisplayConfigurationToStation = async (): Promise<void> => {
    confirm({
      title: t('confirmation'),
      content: t('applyConfigurationWarning'),
      onOk() {
        ApplyDisplayConfiguration();
        return null;
      },
      onCancel() {
        return null;
      },
    });
  };

  const saveChanges = (): void => {
    dispatch(SetDisplayConfiguration(displayConfiguration));
    ApplyDisplayConfigurationToStation();
    props.onClose();
  };

  return (
    <div>
      <h2>{t('displayConfiguration')}</h2>
      <Divider />
      <div className="drawer-string-values">
        <Card title={t('values')}>
          <DisplayConfigurationLanguage
            changeMessage={(newMessage: DisplayConfigurationMessage, index: number) =>
              changeMessage(newMessage, index, true)
            }
            removeMessage={(index: number) => removeMessage(index, true)}
            messages={props.displayConfiguration.Lang1}
            title={t('lang1')}
          />
          <DisplayConfigurationLanguage
            changeMessage={(newMessage: DisplayConfigurationMessage, index: number) =>
              changeMessage(newMessage, index, false)
            }
            removeMessage={(index: number) => removeMessage(index, false)}
            messages={props.displayConfiguration.Lang2}
            title={t('lang2')}
          />
        </Card>
        <div className="drawer-actions">
          <Button className="drawer-cancel" danger type="text" onClick={props.onClose}>
            {t('cancel')}
          </Button>
          <Button type="primary" onClick={saveChanges} loading={isLoading}>
            {t('applyChangesBtn')}
          </Button>
        </div>
      </div>
    </div>
  );
};
