import {
  GET_API_USERS_SUCCESS,
  GET_API_USERS_FAIL,
  API_USERS_LOADING,
  ApiUserActionTypes,
  SET_PARK_NAME_FILTER,
  SET_NAME_FILTER,
  SET_OWNER_NAME_FILTER,
  UPDATE_FILTERED_LIST,
} from './ApiUserActionTypes';
import { ApiUsersState } from './ApiUsersState';
import { FilterApiUsers } from '../ApiUserFilterHelper';

const initialState: ApiUsersState = {
  apiUsers: [],
  filteredApiUsers: [],
  loading: false,
  fetchingFailed: false,
  ownerNamesFilter: [],
  nameFilter: '',
  parkNamesFilter: [],
};

const ApiUsersReducer = (state = initialState, action: ApiUserActionTypes): ApiUsersState => {
  switch (action.type) {
    case GET_API_USERS_SUCCESS:
      return {
        ...state,
        apiUsers: action.apiUsers,
        filteredApiUsers: action.apiUsers,
        fetchingFailed: false,
        loading: false,
      };
    case GET_API_USERS_FAIL:
      return { ...state, loading: false, apiUsers: [], fetchingFailed: true };
    case API_USERS_LOADING:
      return { ...state, loading: true };
    case SET_OWNER_NAME_FILTER:
      return {
        ...state,
        ownerNamesFilter: action.ownerNames,
      };
    case SET_NAME_FILTER:
      return {
        ...state,
        nameFilter: action.name,
      };
    case SET_PARK_NAME_FILTER:
      return {
        ...state,
        parkNamesFilter: action.parkNames,
      };
    case UPDATE_FILTERED_LIST:
      return {
        ...state,
        filteredApiUsers: FilterApiUsers(state),
      };
    default:
      return state;
  }
};

export { ApiUsersReducer };
