import Configuration from '../../../global/Configuration';
import HttpClient from '../../../global/api/HttpClient';

export const GetRolesRequest = async (): Promise<string[]> => {
  try {
    const response = await HttpClient.get(`${Configuration.CSNMS_BASE_URL}/api/security/roles`);
    return response.data;
  } catch (ex) {
    return [];
  }
};
