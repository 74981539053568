import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';

interface TooltipLabelProps {
  label: string;
  name: string;
  align?: string;
}

const TooltipLabel = (props: TooltipLabelProps): ReactElement => {
  const GetLabelClassName = (): string => {
    if (props.align === 'left') {
      return 'left-field-label';
    }
    return 'field-label';
  };

  const tooltipMaxLength = 35;
  const isTextLonger = props.label.length > tooltipMaxLength;
  return (
    <Tooltip placement="topLeft" title={props.label} arrowPointAtCenter>
      <label htmlFor={props.name} className={GetLabelClassName()}>
        {props.label.slice(0, tooltipMaxLength)}
        {isTextLonger && <span>...</span>}
      </label>
    </Tooltip>
  );
};

export default TooltipLabel;
