import { Dispatch } from 'redux';
import { GetCurrentUserRequest } from '../../../users/UserRequests';
import { CurrentUserActionTypes, GET_USER_FAIL, GET_USER_SUCCESS, SET_DEFAULT_USER } from './CurrentUserActionTypes';
import i18n, { getDefaultLanguage } from '../../../../global/i18n';
import { Roles } from '../../../../shared/enums/Roles';

export const GetCurrentUser = () => async (dispatch: Dispatch<CurrentUserActionTypes>) => {
  const user = await GetCurrentUserRequest();

  if (user === undefined) {
    dispatch({
      type: GET_USER_FAIL,
    });
  } else {
    i18n.changeLanguage(user.Language || getDefaultLanguage());
    dispatch({
      type: GET_USER_SUCCESS,
      user,
    });
  }
};

export const SetDefaultUser = (roles: Roles[]) => async (dispatch: Dispatch<CurrentUserActionTypes>) => {
  const user = {
    Roles: roles,
    UserId: 'fakeId',
    Parks: [],
    Owners: [],
    AccountEnabled: true,
    Email: 'test@example.com',
  };
  dispatch({ type: SET_DEFAULT_USER, user });
};
