import React, { ReactElement, useState } from 'react';
import { Guid } from 'guid-typescript';
import produce from 'immer';
import { Button, Card } from 'antd';
import { DisplayConfigurationMessageFields } from './DisplayConfigurationMessageFields';
import { DisplayConfigurationMessage } from '../../../../models/displayConfiguration/DisplayConfigurationMessage';

interface DisplayConfigurationLanguageProps {
  title: string;
  messages: DisplayConfigurationMessage[];
  changeMessage: (newMessage: DisplayConfigurationMessage, index: number) => void;
  removeMessage: (index: number) => void;
}

export const DisplayConfigurationLanguage = (props: DisplayConfigurationLanguageProps): ReactElement => {
  const [messages, setMessages] = useState(
    props.messages.slice(2).map((x) => {
      return { value: x, id: Guid.create().toString() };
    })
  );

  const addMessage = (): void => {
    const newMessages = produce(messages, (draft) => {
      draft.push({ value: { Line1: '', Line2: '', Delay: 0 }, id: Guid.create().toString() });
    });

    setMessages(newMessages);
  };

  const removeMessage = (index: number): void => {
    props.removeMessage(index);
    setMessages(messages.slice(0, index).concat(messages.slice(index + 1)));
  };

  return (
    <div>
      <div>
        <Card
          type="inner"
          title={props.title}
          style={{ marginBottom: '1rem' }}
          extra={
            <Button className="secondary-button" onClick={addMessage}>
              Add
            </Button>
          }
        >
          {messages.map((m, index) => (
            <DisplayConfigurationMessageFields
              key={m.id}
              message={m.value}
              index={index}
              changeMessage={(newMessage: DisplayConfigurationMessage) => props.changeMessage(newMessage, index)}
              removeMessage={() => removeMessage(index)}
            />
          ))}
        </Card>
      </div>
    </div>
  );
};
