import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import TooltipLabel from './ToolTipLabel';

interface LabeledInputTextProps {
  label: string;
  validationRules?: Rule[];
  name: string;
  disabled?: boolean;
  align?: string;
}

const LabeledInputText: FC<LabeledInputTextProps> = ({
  label,
  validationRules,
  name,
  disabled = false,
  align = 'right',
}) => {
  return (
    <div className="field-group">
      <TooltipLabel label={label} name={name} align={align} />
      <Form.Item className="field-value" name={name} rules={validationRules}>
        <Input disabled={disabled} />
      </Form.Item>
    </div>
  );
};

export default LabeledInputText;
