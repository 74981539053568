import React, { FC } from 'react';
import { Breadcrumb, Button, Card, Divider, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import LargeParkSelector from '../../../shared/components/LargeParkSelector';
import LargeOwnerSelector from '../../../shared/components/LargeOwnerSelector';
import RoleSelector from '../../../shared/components/RoleSelector';
import { CreateUserRequest } from '../../UserRequests';
import { ErrorNotification, SuccessNotification } from '../../../../shared/helper/NotificationHelper';
import { User } from '../../models/User';
import { Languages } from '../../../../shared/enums/Languages';
import { ApiErrors } from '../../../../shared/enums/ApiErrors';
import '../users.scss';
import './createUser.scss';

const CreateUser: FC = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const history = useHistory();

  const onFinish = async (values: User): Promise<void> => {
    const returnedUser = await CreateUserRequest(values);
    if (returnedUser === undefined) {
      ErrorNotification(t('createUserError'));
    } else if (returnedUser === ApiErrors.AlreadyExists) {
      ErrorNotification(t('userAlreadyExistsError'));
    } else {
      SuccessNotification(t('createUserSuccess'));
      history.push('/users');
    }
  };

  const onCancel = (): void => {
    history.push('/users');
  };

  return (
    <div className="create-user-page">
      <div className="header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/users">{t('manageUsers')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{t('newUser')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h2>{t('newUser')}</h2>
      </div>

      <Form onFinish={onFinish} layout="vertical">
        <Card className="create-user-card create-user-card-body" title={t('userInformation')}>
          <div className="flex">
            <Form.Item
              name="Email"
              className="medium-input-spaced"
              label={t('email')}
              rules={[
                {
                  type: 'email',
                  message: t('invalidEmailFormat'),
                },
                { required: true, message: t('requiredEmail') },
              ]}
            >
              <Input type="email" placeholder={t('placeHolderEmail')} />
            </Form.Item>

            <Form.Item
              label={t('titleLanguage')}
              name="Language"
              className="medium-input-spaced"
              hasFeedback
              rules={[{ required: true, message: t('requiredLanguage') }]}
            >
              <Select placeholder={t('placeholderLanguage')}>
                <Option value={Languages.FRENCH}>Français</Option>
                <Option value={Languages.ENGLISH}>English</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={t('titleFilterRole')}
              name="Roles"
              className="medium-input-spaced"
              rules={[
                {
                  required: true,
                  message: t('errorMissingRole'),
                },
              ]}
            >
              <RoleSelector />
            </Form.Item>
          </div>
          <Divider />
          <Form.Item name="Owners">
            <LargeOwnerSelector />
          </Form.Item>

          <div className="separator" />
          <Form.Item name="Parks">
            <LargeParkSelector />
          </Form.Item>
          <Form.Item>
            <div className="create-user-actions">
              <Button type="link" danger onClick={onCancel}>
                {t('cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {t('save')}
              </Button>
            </div>
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default CreateUser;
