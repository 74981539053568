import { User } from './models/User';
import { UsersState } from './redux/UsersState';
import { Roles } from '../../shared/enums/Roles';

const FilterOwnerNames = (users: User[], ownerNames?: string[]): User[] => {
  if (ownerNames == null || ownerNames.length === 0) {
    return users;
  }
  return users.filter((u) => u.Owners.filter((value) => ownerNames?.includes(value.Description)).length > 0);
};

const FilterEmail = (users: User[], email: string): User[] => {
  if (email == null || email === undefined) {
    return users;
  }
  return users.filter((u) => u.Email.toLowerCase().includes(email.toLowerCase()));
};

function FilterRoles(users: User[], roles?: string[]): User[] {
  if (roles == null || roles.length === 0) {
    return users;
  }
  return users.filter((u) => validateListRoles(u).filter((value) => roles?.includes(value)).length > 0);

  function validateListRoles(user: User): Roles[] {
    return user.Roles == null ? [] : user.Roles;
  }
}
const FilterParkNames = (users: User[], parkNames?: string[]): User[] => {
  if (parkNames == null || parkNames.length === 0) {
    return users;
  }
  return users.filter((u) => u.Parks.filter((value) => parkNames?.includes(value.Description)).length > 0);
};

export function FilterUsers(state: UsersState): User[] {
  let users = FilterOwnerNames(state.users, state.ownerNamesFilter);
  users = FilterEmail(users, state.emailFilter);
  users = FilterRoles(users, state.roleFilter);
  users = FilterParkNames(users, state.parkNamesFilter);
  return users;
}
