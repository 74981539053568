import React, { FC, useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetOwners } from '../../../owners/redux/OwnersActions';
import { AppState } from '../../../global/redux/store';

type OwnerSelectorComponentProps = {
  onChange: (ownerNames: string[]) => void;
};

const OwnerSelector: FC<OwnerSelectorComponentProps> = (props) => {
  const ownerState = useSelector((state: AppState) => state.owners);
  const { Option } = Select;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOwners());
  }, [dispatch]);

  return (
    <div>
      <div className="filter-name">{t('associatedOwners')}</div>
      <Select
        mode="multiple"
        allowClear
        onChange={props.onChange}
        className="medium-input-spaced"
        placeholder={t('ownerName')}
      >
        {ownerState.owners?.map((owner) => (
          <Option key={owner.OwnerId} value={owner.Description} label={owner.Description}>
            <div>{owner.Description}</div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default OwnerSelector;
