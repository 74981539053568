import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Users from '../../users/users/pages/Users';
import NotFound from '../pages/error/NotFound';
import CreateUser from '../../users/users/pages/create/CreateUser';
import EditUser from '../../users/users/pages/edit/EditUser';
import PrivateRoute from './PrivateRoute';
import { Roles } from '../../shared/enums/Roles';
import Forbidden from '../pages/error/Forbidden';
import ApiUsers from '../../users/api-users/pages/ApiUsers';
import CreateApiUser from '../../users/api-users/pages/create/CreateApiUser';
import EditApiUser from '../../users/api-users/pages/edit/EditApiUser';
import StationConfiguration from '../../stations/configuration/pages/StationConfiguration';
import Unexpected from '../pages/error/Unexpected';
import BadRequest from '../pages/error/BadRequest';

const Routing: FC = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute
        allowedRoles={[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.IT_ADMIN]}
        path="/users/new"
        render={() => <CreateUser />}
      />
      <PrivateRoute
        allowedRoles={[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.IT_ADMIN]}
        path="/users/:id"
        render={() => <EditUser />}
      />
      <PrivateRoute
        allowedRoles={[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.IT_ADMIN]}
        path="/users"
        render={() => <Users />}
      />
      <PrivateRoute
        allowedRoles={[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.IT_ADMIN]}
        path="/apiusers/new"
        render={() => <CreateApiUser />}
      />
      <PrivateRoute
        allowedRoles={[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.IT_ADMIN]}
        path="/apiusers/:id"
        render={() => <EditApiUser />}
      />
      <PrivateRoute
        allowedRoles={[Roles.ADMIN, Roles.SUPER_ADMIN, Roles.IT_ADMIN]}
        path="/apiusers"
        render={() => <ApiUsers />}
      />
      <PrivateRoute
        allowedRoles={[
          Roles.ADMIN,
          Roles.SUPER_ADMIN,
          Roles.OPERATOR,
          Roles.OWNER_OPERATOR,
          Roles.READONLY_OPERATOR,
          Roles.READONLY_PARK_OPERATOR,
          Roles.SUPPORT,
          Roles.SUPPORT_NOC,
        ]}
        path="/stations/:stationId"
        render={() => <StationConfiguration />}
      />
      <Route path="/403" exact>
        <Forbidden />
      </Route>
      <Route path="/400/:message" exact>
        <BadRequest />
      </Route>
      <Route path="/500" exact>
        <Unexpected />
      </Route>

      <Route>
        {' '}
        <NotFound />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Routing;
