import { Dispatch } from 'redux';
import {
  API_USERS_LOADING,
  ApiUserActionTypes,
  GET_API_USERS_FAIL,
  GET_API_USERS_SUCCESS,
  SET_NAME_FILTER,
  SET_OWNER_NAME_FILTER,
  SET_PARK_NAME_FILTER,
  UPDATE_FILTERED_LIST,
} from './ApiUserActionTypes';
import { GetApiUsersRequest } from '../ApiUserRequests';

export const GetApiUsers = () => async (dispatch: Dispatch<ApiUserActionTypes>) => {
  dispatch({
    type: API_USERS_LOADING,
  });

  const apiUsers = await GetApiUsersRequest();

  if (apiUsers.length > 0) {
    dispatch({
      type: GET_API_USERS_SUCCESS,
      apiUsers: apiUsers.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)),
    });
  } else {
    dispatch({
      type: GET_API_USERS_FAIL,
    });
  }
};

export const SetOwnerNames = (ownerNames: string[]) => (dispatch: Dispatch<ApiUserActionTypes>) => {
  dispatch({ type: SET_OWNER_NAME_FILTER, ownerNames });
  dispatch({ type: UPDATE_FILTERED_LIST });
};

export const SetName = (value: string) => (dispatch: Dispatch<ApiUserActionTypes>) => {
  dispatch({ type: SET_NAME_FILTER, name: value });
  dispatch({ type: UPDATE_FILTERED_LIST });
};

export const SetParkNames = (parkNames: string[]) => (dispatch: Dispatch<ApiUserActionTypes>) => {
  dispatch({ type: SET_PARK_NAME_FILTER, parkNames });
  dispatch({ type: UPDATE_FILTERED_LIST });
};
