type ConfigurationType = {
  CSNMS_BASE_URL: string;
  WITH_CREDENTIALS: boolean;
  TITLE: string;
  FAVICON: string;
  LOGO: string;
  THEME: string;
  DEACTIVATE_AUTH: boolean;
};

const configuration: ConfigurationType = {
  CSNMS_BASE_URL: process.env.REACT_APP_CSNMS_BASE_URL || '',
  WITH_CREDENTIALS: JSON.parse(process.env.REACT_APP_WITH_CREDENTIALS || ''),
  TITLE: process.env.REACT_APP_TITLE || '',
  FAVICON: process.env.REACT_APP_FAVICON || '',
  LOGO: process.env.REACT_APP_LOGO || '',
  THEME: process.env.REACT_APP_THEME || '',
  DEACTIVATE_AUTH: JSON.parse(process.env.REACT_APP_DEACTIVATE_AUTH || 'false'),
};

export default configuration;
