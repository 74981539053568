import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationState } from '../../redux/ConfigurationState';
import ConfigurationCategory from '../ConfigurationCategory';
import IdentificationData from './IdentificationData';
import { useIsUserAdmin } from '../../helpers/UseIsAdmin';

interface IdentificationProps {
  configurationState: ConfigurationState;
}

const Identification = (props: IdentificationProps): ReactElement => {
  const { t } = useTranslation();
  const isAdmin = useIsUserAdmin();

  if (
    props.configurationState.chargingStationInfo !== undefined &&
    props.configurationState.displayedCategories?.showIdentification
  ) {
    return (
      <ConfigurationCategory
        id="identification"
        categoryTitle={t('identification')}
        CategoryData={
          <IdentificationData stationInfo={props.configurationState.chargingStationInfo} isReadonly={!isAdmin} />
        }
      />
    );
  }
  return <div />;
};

export default Identification;
