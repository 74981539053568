import React, { ReactElement } from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import Configuration from '../../Configuration';

const Unexpected = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Result
      status="500"
      title={t('UnexpectedMessage')}
      extra={
        <Button type="primary" href={Configuration.CSNMS_BASE_URL}>
          {t('goToCSNMS')}
        </Button>
      }
    />
  );
};

export default Unexpected;
