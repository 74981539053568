import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationState } from '../../redux/ConfigurationState';
import ConfigurationCategory from '../ConfigurationCategory';
import { PricingData } from './PricingData';
import { useIsInRole } from '../../helpers/UseIsInRole';
import { Roles } from '../../../../shared/enums/Roles';

interface PricingProps {
  configurationState: ConfigurationState;
}

export const Pricing = (props: PricingProps): ReactElement => {
  const { t } = useTranslation();

  const isEditAllowed = useIsInRole([Roles.SUPER_ADMIN, Roles.ADMIN, Roles.OPERATOR, Roles.OWNER_OPERATOR]);

  if (
    props.configurationState.chargingStationInfo !== undefined &&
    props.configurationState.chargingStationPricing != null &&
    props.configurationState.displayedCategories?.showPricing
  ) {
    return (
      <ConfigurationCategory
        id="pricing"
        categoryTitle={t('pricing')}
        CategoryData={
          <PricingData
            stationId={props.configurationState.chargingStationInfo.ChargingStationId}
            stationPricing={props.configurationState.chargingStationPricing}
            isEditAllowed={isEditAllowed}
          />
        }
      />
    );
  }

  return <div />;
};
