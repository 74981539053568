import { Either, left, right } from '@sweet-monads/either';
import HttpClient from '../../../global/api/HttpClient';
import { ChargingStationConfiguration } from '../models/ChargingStationConfiguration';
import { ChargingStationInfo } from '../models/ChargingStationInfo';
import { ChargingStationPricing } from '../models/pricing/ChargingStationPricing';
import { ApiErrors } from '../../../shared/enums/ApiErrors';
import { RequestStatus } from '../enums/RequestStatus';
import { AdvancedConfigurationComponent } from '../models/AdvancedConfigurationComponent';
import { Firmware } from '../models/Firmware';
import { ChangeExpectedFirmwareRequest } from '../models/ChangeExpectedFirmwareRequest';
import { DisplayConfiguration } from '../models/displayConfiguration/DisplayConfiguration';
import { AmperageConfiguration } from '../models/AmperageConfiguration';

export const GetConfigurationRequest = async (
  stationId: string,
  includeStationValues = false
): Promise<Either<ApiErrors, ChargingStationConfiguration>> => {
  try {
    const response = await HttpClient.get(`/api/configuration/${stationId}`, { params: { includeStationValues } });
    return right(response.data);
  } catch (ex: any) {
    if (ex.status === 400) {
      return left(ApiErrors.BadRequest);
    }
    if (ex.status === 404) {
      return left(ApiErrors.NotFound);
    }
    return left(ApiErrors.InternalServerError);
  }
};

export const EditConfigurationRequest = async (
  stationInfo: ChargingStationInfo,
  displayConfiguration: DisplayConfiguration,
  amperageConfiguration: AmperageConfiguration | undefined,
  advancedComponents: AdvancedConfigurationComponent[],
  stationId: string
): Promise<Either<ApiErrors, ChargingStationConfiguration>> => {
  try {
    const response = await HttpClient.put(`/api/configuration/${stationId}`, {
      ChargingStationInfo: stationInfo,
      DisplayConfiguration: displayConfiguration,
      AmperageConfiguration: amperageConfiguration,
      AdvancedConfigurationComponents: advancedComponents,
    });
    return right(response?.data);
  } catch (err) {
    return left(ApiErrors.InternalServerError);
  }
};

export const ApplyConfigurationToStationRequest = async (
  stationId: string
): Promise<Either<ApiErrors, RequestStatus>> => {
  try {
    const response = await HttpClient.post(
      `/api/configuration/${stationId}/applyConfiguration?timeoutInSeconds=300`,
      {}
    );
    return right(response.data);
  } catch (err) {
    return left(ApiErrors.InternalServerError);
  }
};

export const ApplyPricingToStationRequest = async (
  pricing: ChargingStationPricing,
  stationId: string
): Promise<Either<ApiErrors, ChargingStationConfiguration>> => {
  try {
    const response = await HttpClient.post(`/api/configuration/${stationId}/applyPricing`, pricing);
    return right(response?.data);
  } catch (err) {
    return left(ApiErrors.InternalServerError);
  }
};

export const ApplyDisplayConfigurationRequest = async (
  displayConfiguration: DisplayConfiguration,
  stationId: string
): Promise<Either<ApiErrors, undefined>> => {
  try {
    await HttpClient.post(`/api/configuration/${stationId}/applyDisplayConfiguration`, displayConfiguration);
    return right(undefined);
  } catch (err) {
    return left(ApiErrors.InternalServerError);
  }
};

export const ApplyFactoryReset = async (stationId: string): Promise<Either<ApiErrors, undefined>> => {
  try {
    await HttpClient.post(`/api/configuration/${stationId}/factoryReset`, {});
    return right(undefined);
  } catch (err) {
    return left(ApiErrors.InternalServerError);
  }
};

export const GetExpectedFirmwares = async (stationId: string): Promise<Either<ApiErrors, Firmware[]>> => {
  try {
    const response = await HttpClient.get(`/api/configuration/${stationId}/firmwares`);
    return right(response.data);
  } catch (err) {
    return left(ApiErrors.InternalServerError);
  }
};

export const ChangeExpectedFirmware = async (
  stationId: string,
  body: ChangeExpectedFirmwareRequest
): Promise<Either<ApiErrors, undefined>> => {
  try {
    await HttpClient.post(`/api/configuration/${stationId}/firmware`, body);
    return right(undefined);
  } catch (err) {
    return left(ApiErrors.InternalServerError);
  }
};
